import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CustomTable from "../../../components/table/table";
import "./view.css";
import {
  admin_candidates_confirm,
  admin_candidates_delete,
  admin_candidates_reminder,
  get_admin_downloaded_candidates_details,
  get_candidates_details,
  get_manpower_details,
  update_admin_candidates_status,
  update_candidates_status,
  update_manpower_status,
} from "../admin.api";
import Snackbar from "../../../components/snackbar/snackbar";
import Loader from "../../../components/loader/loader";
import BreadCrumbsComp from "../../../components/navigator/breadcrumbs";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import moment from "moment";
export default function View(props) {
  const [data, setData] = useState([]);
  const [dataf, setDataf] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [url, setUrl] = useState("");
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const handleEdit = () => { };
  const handleDownload = (item) => {
    let path = `${result}${item.file_upload_certificate}`;
    window.open(path, "_blank");
  };
  const handlefullDownload = (item) => {
    let path = `${item.file_upload_certificate}`;
    window.open(path, "_blank");
  };
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const selectedParam = useParams();
  const getDetails = (id) => {
    const data = {
      candidate_id: selectedParam?.id,
      company_id: selectedParam?.id2,
    };
    get_admin_downloaded_candidates_details(data).then((res) => {
      let tdata = res.data?.candidate_details;

      setData(tdata);
      setDataf(res?.data);
      setIsActive(false);
    });
    // get_candidates_details(id).then((res) => {
    //   let tdata = res.data;

    //   setData(tdata);
    //   setIsActive(false);
    // });
  };
  useEffect(() => {
    getDetails(selectedParam.id);
  }, []);
  const handleDownloadBiodata = () => {
    let path = `${result}${data.biodata}`;
    window.open(path, "_blank");
  };
  const handleDownloadExpCert = (data) => {
    let path = `${result}${data}`;
    window.open(path, "_blank");
  };
  const handleDownloadIdCert = (data) => {
    let path = `${data}`;
    window.open(path, "_blank");
  };

  const saMessage = (status, type, id2) => {
    Swal.fire({
      inputLabel: "Message",
      input: "textarea",
      showCancelButton: false,
      confirmButtonColor: "#0A223D",
      confirmButtonText: "Submit",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      inputValidator: (value) => {
        if (!value) {
          return "Please describe the reason";
        }
      },
      showCancelButton: true,
    }).then((message) => {
      if (message.isConfirmed === true) {
        let mandetails = data?.id;
        console.log(message.value);
        let mdata = {};
        if (adminType === 1) {
          if (status === 1) {
            mdata = {
              // em_id: mandetails,
              reminder: message.value,
            };
          }
        }
        // let data = { status: status, super_admin_message: message.value };
        setIsActive(true);
        if (type === "confirm") {
          admin_candidates_confirm(id2)
            .then((res) => {
              setSnackMessage(res.message);
              setIsSnackError(false);
              setSnackOpen(true);
              setIsActive(false);
            })
            .catch((error) => {
              setSnackMessage(error.message);
              setIsSnackError(true);
              setSnackOpen(true);
              setIsActive(false);
            });
        } else if (type === "reminder") {
          admin_candidates_reminder(mdata, id2)
            .then((res) => {
              setSnackMessage(res.message);
              setIsSnackError(false);
              setSnackOpen(true);
              setIsActive(false);
            })
            .catch((error) => {
              setSnackMessage(error.message);
              setIsSnackError(true);
              setSnackOpen(true);
              setIsActive(false);
            });
        } else if (type === "delete") {
          admin_candidates_delete(id2)
            .then((res) => {
              setSnackMessage(res.message);
              setIsSnackError(false);
              setSnackOpen(true);
              setIsActive(false);
            })
            .catch((error) => {
              setSnackMessage(error.message);
              setIsSnackError(true);
              setSnackOpen(true);
              setIsActive(false);
            });
        }
        getDetails(selectedParam.id);
      }
    });
  };
  const handleAdminActions = (type, id2) => {
    let detId = data?.user_id;
    // let mdata = { status_id: status, em_id: mandetails };
    setIsActive(true);
    if (type === "confirm") {
      admin_candidates_confirm(id2)
        .then((res) => {
          setSnackMessage(res.message);
          setIsSnackError(false);
          setSnackOpen(true);
          setIsActive(false);
        })
        .catch((error) => {
          setSnackMessage(error.message);
          setIsSnackError(true);
          setSnackOpen(true);
          setIsActive(false);
        });
    } else if ("delete") {
      admin_candidates_delete(id2)
        .then((res) => {
          setSnackMessage(res.message);
          setIsSnackError(false);
          setSnackOpen(true);
          setIsActive(false);
        })
        .catch((error) => {
          setSnackMessage(error.message);
          setIsSnackError(true);
          setSnackOpen(true);
          setIsActive(false);
        });
    }
    getDetails(selectedParam.id);
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push(`/detailed-profiles-list/${selectedParam.id2}`);
    }
  };
  const handleBack = () => {
    props.history.push(`/detailed-profiles-list/${selectedParam.id2}`);
  }
  // const expArea = JSON.parse(data?.expertise_area)
  // console.log(expArea)
  return (
    <div>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />

      <Loader open={isActive} />
      <div className="view-container">
        <span className="d-flex justify-content-between">
          <h6 className="mb-0 pt-2">
            <b>Jobseeker Details</b>
          </h6>
          {/*      
        <button type="button" className="btn btn-primary">
          Back to List
        </button> */}
        </span>
        <hr />
        {/* <button
          type="button"
          className="btn btn-secondary btn-sm  mb-3"
          onClick={(e) => {
            props.history.push("/event-list/");
          }}
        >
          Back
        </button> */}
        <div className="data">
          <div className="row">
            <div className="col-3">Are you a native of Kerala?</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data?.native_kerala == 1 ? "Yes" : "No" ?? ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Identity Type</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.identity_type}</div>
          </div>
          <div className="row">
            <div className="col-3">Identity Number</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.identity_no}</div>
          </div>
          <div className="row">
            <div className="col-3"> Identity Proof</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <button
                className="btn btn-approve btn-success"
                onClick={(e) => handleDownloadIdCert(data?.identity_file)}
              >
                Download File
              </button>
            </div>
          </div>
          {data?.native_kerala != 1 && (
            <div className="row">
              <div className="col-3">Are you a Non Resident Keralite?</div>
              <div className="col-1">:</div>
              <div className="col-7">Yes</div>
            </div>
          )}
          {data?.native_address_type && (
            <div className="row">
              <div className="col-3">Proof of Native Address</div>
              <div className="col-1">:</div>
              <div className="col-7">{data?.native_address_type}</div>
            </div>
          )}
          {data?.native_address_type && (
            <div className="row">
              <div className="col-3">Uploaded Proof of Native Address</div>
              <div className="col-1">:</div>
              <div className="col-7">
                <button
                  className="btn btn-approve btn-success"
                  onClick={(e) => handleDownloadIdCert(data?.non_keralite_file)}
                >
                  Download File
                </button>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-3">Fresher?</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.fresher}</div>
          </div>
          <div className="row">
            <div className="col-3">Name (as per Aadhaar)</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {/* {data?.prefix ? data?.prefix + "." : "Mr."} {data?.name ?? ""}{" "} */}
              {data?.name ?? ""}
            </div>
          </div>

          <div className="row">
            <div className="col-3">Date of Birth</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data?.dob
                ? moment(data?.dob, "YYYY-MM-DD").format("DD-MM-YYYY")
                : ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Gender</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.gender ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Address</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.address}</div>
          </div>
          <div className="row">
            <div className="col-3">Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.email}</div>
          </div>
          <div className="row">
            <div className="col-3">Phone Number</div>
            <div className="col-1">:</div>
            <div className="col-7">+91 {data?.contact}</div>
          </div>
          <div className="row">
            <div className="col-3">Alternate Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.alt_email}</div>
          </div>
          <div className="row">
            <div className="col-3">Alternate Phone</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data?.alt_code ? "+" : ""}
              {data?.alt_code} {data?.alt_contact}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Pin Code</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.pin_code}</div>
          </div>
          <div className="row">
            <div className="col-3">Marital Status</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.marital_status ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Father / Spouse Name</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.parent_name ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Caste</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.caste ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Are you Differently Abled?</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.handicapped ?? ""}</div>
          </div>
          {data?.handicapped == "Yes" && (
            <div className="row">
              <div className="col-3"> Disability Certificate</div>
              <div className="col-1">:</div>
              <div className="col-7">
                <button
                  className="btn btn-approve btn-success"
                  onClick={(e) => handleDownloadIdCert(data?.handicapped_file)}
                >
                  Download File
                </button>
              </div>
            </div>
          )}

          <div className="row">
            <div className="col-3">Blood Group</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data?.blood_group == "Other" ? (
                <span className="text-danger">{data?.other_blood_group}</span>
              ) : (
                data?.blood_group
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-3"> Photo</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <button
                className="btn btn-approve btn-success"
                onClick={(e) => handleDownloadIdCert(data?.photo)}
              >
                Download File
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-3"> CV File</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <button
                className="btn btn-approve btn-success"
                onClick={(e) => handleDownloadIdCert(data?.cv_upload)}
              >
                Download File
              </button>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-3">Highest Qualification</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.qualification_type}</div>
          </div>
          <div className="row">
            <div className="col-3">Qualification</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.qualification}</div>
          </div> */}
          <div className="row">
            <div className="col-3">Created At</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data?.created_at
                ? moment(data?.created_at).format("DD-MM-YYYY")
                : ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3 pb-2">Educational Qualification</div>
            <div className="col-1">:</div>
            <div className="col-12 pb-2  mt-00">
              <CustomTable
                {...props}
                columns={[
                  {
                    title: "Qualification Type",
                    field: "edu_type_parent",
                  },
                  { title: "Qualification", field: "edu_type_sub_one" },
                  { title: "Stream", field: "edu_type_sub_two" },
                  {
                    title: "Stream Specification",
                    field: "edu_type_others_text",
                    type: "otherSpec",
                  },
                  { title: " Board/University", field: "university" },
                  { title: "City/State", field: "location" },
                  { title: "Year of Passing", field: "year" },
                  { title: "Attainment Type", field: "edu_qualification_type" },
                  { title: "Attainment", field: "edu_qualification_text" },
                  { title: "Equivalent Percentage", field: "percentage" },
                  {
                    title: "Certificate",
                    field: "edu_certificate",
                    type: "download",
                  },
                  // {
                  //   title: "Certificate",
                  //   field: "file_upload_certificate",
                  //   type: "download",
                  // },
                ]}
                data={data?.education ?? []}
                handleDownload={handleDownload}
              />
            </div>
          </div>
          {/* <div className="row">
            <div className="col-3">Alternate Phone</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data?.alternate_phone &&
                data?.country_code &&
                data?.country_code !== "" &&
                data?.country_code !== "null" &&
                data?.country_code !== "undefined" && (
                  <>
                    {data?.country_code ?? ""}{" "}
                    {data?.country_code && data?.country_code !== ""
                      ? "-"
                      : ""}{" "}
                  </>
                )}
              {data?.alternate_phone ?? ""}
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-3">Languages Known</div>
            <div className="col-1">:</div>
            <div className="col-7">
              Malayalam&nbsp;&nbsp;|&nbsp;&nbsp;English&nbsp;&nbsp;|&nbsp;&nbsp;Hindi
            </div>
          </div> */}

          {/* <div className="row">
            <div className="col-3">Area of Expertise:</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data &&
                data?.area_of_expertise &&
                data?.area_of_expertise.length !== 0 && (
                  <>
                    {data?.area_of_expertise?.map((item, index) => (
                      <div>{item.expertise_name}</div>
                    ))}
                  </>
                )}
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-3">Any other area of interest</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data &&
                data.area_of_interest &&
                data?.area_of_interest.length !== 0 && (
                  <>
                    {data?.area_of_interest?.map((item, index) => (
                      <span>
                        {index !== 0 && " | "}
                        {item.area_of_interest_name}
                      </span>
                    ))}
                  </>
                )}
            </div>
          </div> */}

          {/* <div className="row">
            <div className="col-3">Employment Details</div>
            <div className="col-1">:</div>
            <div className="col-7  mt-00">
              <CustomTable
                {...props}
                columns={[
                  { title: " Employer", field: "employer" },
                  { title: "Contact", field: "contact" },
                  { title: "Email", field: "email" },
                  { title: "Designation", field: "position" },
                  { title: "From", field: "from" },
                  { title: "To", field: "to" },
                  {
                    title: "Certificate",
                    field: "exp_certificate",
                    type: "download",
                  },
                ]}
                data={data?.employment ?? []}
                handleDownload={handlefullDownload}
              />
            </div>
          </div> */}

          {data?.fresher === "No" && (
            <div className="row">
              <div className="col-3">Employment Details</div>
              <div className="col-1">:</div>
              <div className="col-7">
                {data && data?.employment && data?.employment?.length !== 0 && (
                  <>
                    {data?.employment?.map((item, index) => (
                      <>
                        <div className="white row">
                          <div className="col-6">
                            <b>Employer</b>
                            <p>{item.employer}</p>
                          </div>
                          <div className="col-6">
                            <b>Contact No</b>
                            <p>{`${item.contact}`}</p>
                          </div>
                          <div className="col-6">
                            <b>Email Id</b>
                            <p>{item.email}</p>
                          </div>
                          <div className="col-6">
                            <b>Designation</b>
                            <p>{item.position}</p>
                          </div>
                          <div className="col-6">
                            <b>Currently Working</b>
                            <p>{item?.working == "Yes" ? "Yes" : "No"}</p>
                          </div>
                          <div className="col-6">
                            <b>{item?.working == "Yes" ? "From" : "Period"}</b>
                            <p>
                              {item.from}{" "}
                              {item?.working == "Yes" ? (
                                <>(till now)</>
                              ) : (
                                <> to {item?.to}</>
                              )}
                            </p>
                          </div>
                          {item?.working != "Yes" && (
                            <div className="col-6">
                              <b>Reason for Leaving</b>
                              <p>{item.reason}</p>
                            </div>
                          )}
                          <div className="col-6">
                            <b>Address</b>
                            <p>{item.address}</p>
                          </div>
                          <div className="col-6">
                            <b>
                              {item.working == "Yes"
                                ? "Appointment Letter"
                                : "Experience Certificate"}
                            </b>
                            <p>
                              <button
                                className="btn btn-approve btn-success m-0"
                                onClick={(e) =>
                                  handleDownloadIdCert(item.exp_certificate)
                                }
                              >
                                Download File
                              </button>
                            </p>
                          </div>
                        </div>
                        {data?.employment.length > 1 &&
                          data.employment.length !== index + 1 && <hr />}
                      </>
                    ))}
                  </>
                )}
              </div>
            </div>
          )}
          {(data?.reference_contact1 || data?.reference_email1) &&
          <div className="row">
            <div className="col-3">Reference 1</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <>
                <div className="white">
                  <div>
                    <b>Name</b>
                    <p>{data?.reference_name1}</p>
                  </div>
                  <div>
                    <b>Designation</b>
                    <p>{`${data?.reference_designation1}`}</p>
                  </div>
                  <div>
                    <b>Address</b>
                    <p>{`${data?.reference_address1}`}</p>
                  </div>
                  <div>
                    <b>Contact No</b>
                    <p>+{data?.reference_contact1 ? `${data?.reference_contact1.split("-")[0]} ${data?.reference_contact1.split("-")[1]}` : ""}</p>
                  </div>
                  <div>
                    <b>Email Id</b>
                    <p>{data?.reference_email1}</p>
                  </div>
                </div>
              </>
            </div>
          </div>}
          {(data?.reference_contact2 || data?.reference_email2) &&
          <div className="row">
            <div className="col-3">Reference 2</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <>
                <div className="white">
                  <div>
                    <b>Name</b>
                    <p>{data?.reference_name2}</p>
                  </div>
                  <div>
                    <b>Designation</b>
                    <p>{`${data?.reference_designation2}`}</p>
                  </div>
                  <div>
                    <b>Address</b>
                    <p>{`${data?.reference_address2}`}</p>
                  </div>
                  <div>
                    <b>Contact No</b>
                    <p>+{data?.reference_contact2 ? `${data?.reference_contact2.split("-")[0]} ${data?.reference_contact2.split("-")[1]}` : ""}</p>
                  </div>
                  <div>
                    <b>Email Id</b>
                    <p>{data?.reference_email2}</p>
                  </div>
                </div>
              </>
            </div>
          </div>}
          <div className="row">
            <div className="col-3">Area of Interest</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.interest_type ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Preferences</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data && data?.interest && data?.interest.length !== 0 && (
                <>
                  {JSON.parse(data?.interest)?.map((item, index) => (
                    <div>{item}</div>
                  ))}
                  {data?.any_other_domain && (
                    <div>{data?.any_other_domain}</div>
                  )}
                </>
              )}
            </div>
          </div>
          {dataf?.appointment_remarks && (
            <div className="row">
              <div className="col-3">Appointment Remarks</div>
              <div className="col-1">:</div>
              <div className="col-7">{dataf.appointment_remarks}</div>
            </div>
          )}
          {dataf?.join_remarks && (
            <div className="row">
              <div className="col-3">Joining Remarks</div>
              <div className="col-1">:</div>
              <div className="col-7">{dataf.join_remarks}</div>
            </div>
          )}
          {dataf?.download_date && (
            <div className="row">
              <div className="col-3">Downloaded Date</div>
              <div className="col-1">:</div>
              <div className="col-7">
                {new Date(dataf.download_date).toLocaleDateString('en-GB')}
              </div>
            </div>
          )}

          {dataf?.company_name && (
            <div className="row">
              <div className="col-3">Company Name</div>
              <div className="col-1">:</div>
              <div className="col-7">{dataf.company_name}</div>
            </div>
          )}
          {dataf?.transaction_id && (
            <div className="row">
              <div className="col-3">Transaction Id</div>
              <div className="col-1">:</div>
              <div className="col-7">{dataf.transaction_id}</div>
            </div>
          )}
          {dataf?.transaction_date && (
            <div className="row">
              <div className="col-3">Transaction Date</div>
              <div className="col-1">:</div>
              <div className="col-7">{dataf.transaction_date}</div>
            </div>
          )}
          {dataf?.amount && (
            <div className="row">
              <div className="col-3">Amount</div>
              <div className="col-1">:</div>
              <div className="col-7">{dataf.amount}</div>
            </div>
          )}
          {dataf?.method && (
            <div className="row">
              <div className="col-3">Payment Method</div>
              <div className="col-1">:</div>
              <div className="col-7">{dataf.method}</div>
            </div>
          )}


          {/* <div className="row">
            <div className="col-3">
              Are you currently attached / committed to any Organization ? Give
              details
            </div>
            <div className="col-1">:</div>
            <div className="col-7">jjjjj</div>
          </div> */}
          {/* <div className="row">
            <div className="col-3"> Photo</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <img
                className="res_photo"
                src={`${result}${data?.photo}`}
                alt=""
              />
            </div>
          </div> */}

          {adminType === 1 && (
            <>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-approve btn-success mr-2"
                  onClick={(e) =>
                    // handleAdminActions("delete", dataf?.downloaded_profile_id)
                    handleBack()
                  }
                >
                  Go Back
                </button>
                {(dataf?.downloaded_profile_status === 0 ||
                  dataf?.downloaded_profile_status === 1) && (
                    <button
                      type="button"
                      className="btn btn-approve btn-success mr-2"
                      onClick={(e) =>
                        saMessage(1, "reminder", dataf?.downloaded_profile_id)
                      }
                    >
                      Reminder
                    </button>
                  )}
              </div>
            </>
          )}




          {adminType === 2 && (
            <>
              <div className="d-flex justify-content-center w-100">
                <button
                  type="button"
                  className="btn btn-approve btn-success mr-2"
                  onClick={(e) =>
                    // handleAdminActions("delete", dataf?.downloaded_profile_id)
                    handleBack()
                  }
                >
                  Go Back
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
