import moment from "moment";
import React, { useEffect, useState } from "react";
import CommonForm from "../../../../../components/commonform/commonForm";
import Loader from "../../../../../components/loader/loader";
import Snackbar from "../../../../../components/snackbar/snackbar";
import {
  common_file_upload,
  is_email_registered,
  qualifications_list,
} from "../../../user.api";
let eighteenYearsAgo = new Date();

eighteenYearsAgo = eighteenYearsAgo.setFullYear(
  eighteenYearsAgo.getFullYear() - 18
);
let sYearsAgo = new Date();

sYearsAgo = sYearsAgo.setFullYear(sYearsAgo.getFullYear() - 70);

export default function AreaDetails(props) {
  const admin_type = [
    {
      domain_name: "Admin/HR",
      checked: false,
    },
    {
      domain_name: "Finance/Accounts",
      checked: false,
    },
    {
      domain_name: "Marketing/Sales",
      checked: false,
    },
  ];

  const technical_type = [
    {
      domain_name: "Design/Analysis",
      checked: false,
    },
    {
      domain_name: "Production",
      checked: false,
    },
    {
      domain_name: "Planning",
      checked: false,
    },
    {
      domain_name: "Operations",
      checked: false,
    },
    {
      domain_name: "Quality Control",
      checked: false,
    },
    {
      domain_name: "Testing",
      checked: false,
    },
    {
      domain_name: "Supervision",
      checked: false,
    },
    {
      domain_name: "Technician",
      checked: false,
    },
  ];

  const management_type = [
    {
      domain_name: "Project Management",
      checked: false,
    },
    {
      domain_name: "Operations Management",
      checked: false,
    },
    {
      domain_name: "Logistics Management",
      checked: false,
    },
    {
      domain_name: "Planning",
      checked: false,
    },
    {
      domain_name: "Finance",
      checked: false,
    },
  ];

  const [formData, setFormData] = useState(props.data);
  const [count, setCount] = useState(1);
  const [keyvalue, setKeyvalue] = useState(0);
  const [defaultkey, setdefaultkey] = useState(props.defaultkey);
  const [err, setErr] = useState(false);
  const [pinErr, setPinErr] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [decOne, setDecOne] = useState([]);
  const [decTwo, setDecTwo] = useState(false);
  const [intArea, setIntArea] = useState([]);
  const [updateKey, setUpdateKey] = useState(0);
  const [uKey, setUKey] = useState(0);
  const [qual, setQual] = useState([]);
  const [qualSub, setQualSub] = useState([]);
  const [qualStream, setQualStream] = useState([]);
  useEffect(() => {
    setFormData(props.data);
    setCount(count + 1);
  }, [props.data]);
  // console.log(props.data);

  const CheckEducation = () => {
    let errors = [];

    formData.education.forEach((item, index) => {
      if (!item?.edu_qualification_type) {
        errors.push(
          `Please fill in the mark type in education at index ${index + 1}`
        );
      }
      if (!item?.edu_qualification_text) {
        errors.push(
          `Please fill in the mark in education at index ${index + 1}`
        );
      }
    });

    return errors;
  };

  const handlepreview = (e) => {
    e.preventDefault();
    let maxDate = new Date(moment(new Date(eighteenYearsAgo)));
    let minDate = new Date(moment(new Date(sYearsAgo)));
    let emailData = { email: formData.email };
    // let refEmail1 = formData.reference_email1;
    // let refEmail2 = formData.reference_email2;
    let Data = formData;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

    let selectedareaofinterest = [];
    formData?.employment.forEach((item, i) => {
      if (
        emailRegex.test(item?.email) === false &&
        formData?.fresher === "No"
      ) {
        setDecOne(...decOne);
      }
    });
    props.areaOfInterest.forEach((item) => {
      if (item.checked) {
        selectedareaofinterest.push(item);
      }
    });
    Data = {
      ...Data,
      area_of_interest: selectedareaofinterest,
    };
    if (CheckEducation().length !== 0) {
      setSnackMessage("Please fill in the education details correctly");
      setIsSnackError(true);
      setSnackOpen(true);
    }
    if (CheckEducation().length !== 0) {
      return false;
    }
    console.log(CheckEducation());
    if (moment(formData.dob, "DD-MM-YYYY") <= moment(maxDate, "DD-MM-YYYY")) {
      if (emailRegex.test(formData?.email) === true)
        if (!props.update) {
          is_email_registered(emailData)
            .then((res) => {
              if (
                formData.identity_file === null ||
                formData.identity_file === undefined ||
                formData.identity_file === ""
              ) {
                setSnackMessage("Please choose a valid identification file");
                setIsSnackError(true);
                setSnackOpen(true);
              } else {
                let validExp = true;
                formData.employment.forEach((item) => {
                  if (
                    formData?.fresher === "No" &&
                    (item.exp_certificate === null ||
                      item.exp_certificate === undefined ||
                      item.exp_certificate === "")
                  ) {
                    setSnackMessage(
                      `Please choose a valid experience certificate file `
                    );
                    setIsSnackError(true);
                    setSnackOpen(true);
                    validExp = false;
                  }
                });

                if (validExp) {
                  let validEduc = true;
                  // formData.education.forEach((item) => {
                  //   if (
                  //     item.file_upload_certificate === null ||
                  //     item.file_upload_certificate === undefined ||
                  //     item.file_upload_certificate === ""
                  //   ) {
                  //     setSnackMessage(
                  //       `Please choose a valid educational certificate file for ${item.examination_passed}`
                  //     );
                  //     setIsSnackError(true);
                  //     setSnackOpen(true);
                  //     validEduc = false;
                  //   }
                  // });
                  if (validEduc) {
                    if (
                      formData?.native_kerala &&
                      formData?.gender &&
                      formData?.marital_status &&
                      formData?.handicapped &&
                      formData?.caste &&
                      formData?.interest_type &&
                      formData?.fresher
                    ) {
                      if (formData?.native_kerala != 1) {
                        if (
                          !formData?.native_address_type ||
                          !formData?.non_keralite_file
                        ) {
                          setSnackMessage(
                            `Please Select a Valid Native Address and Proof for Native`
                          );
                          setIsSnackError(true);
                          setSnackOpen(true);
                          return;
                        }
                      }
                      if (formData?.handicapped == "Yes") {
                        if (!formData?.handicapped_file) {
                          setSnackMessage(
                            `Please Upload a Valid Proof for Disability`
                          );
                          setIsSnackError(true);
                          setSnackOpen(true);
                          return;
                        }
                      }
                      if (formData?.blood_group == "Other") {
                        if (!formData?.other_blood_group) {
                          setSnackMessage(`Please Specify the Blood Group`);
                          setIsSnackError(true);
                          setSnackOpen(true);
                          return;
                        }
                      }

                      if (decOne.length === 0) {
                        if (formData?.conf1 && formData?.conf2) {
                          if (formData?.photo) {
                            props.action(0, Data, "basicData");
                          } else {
                            setSnackMessage(`Please select a photo`);
                            setIsSnackError(true);
                            setSnackOpen(true);
                          }
                        } else {
                          setSnackMessage(`Please check the disclaimers`);
                          setIsSnackError(true);
                          setSnackOpen(true);
                        }
                      } else {
                        setSnackMessage(
                          `Please check the emails provided for experience details`
                        );
                        setIsSnackError(true);
                        setSnackOpen(true);
                      }

                      // else {
                      //   setSnackMessage(
                      //     `Please choose a valid email for reference 2 `
                      //   );
                      //   setIsSnackError(true);
                      //   setSnackOpen(true);
                      // }

                      // else {
                      //   setSnackMessage(
                      //     `Please choose a valid email for reference 1 `
                      //   );
                      //   setIsSnackError(true);
                      //   setSnackOpen(true);
                      // }
                    } else {
                      setSnackMessage(`Please fill in all the required fields`);
                      setIsSnackError(true);
                      setSnackOpen(true);
                    }
                  }
                }
              }
              // setSnackOpen(true);
            })
            .catch((error) => {
              setSnackMessage(error.response.data.message);
              setIsSnackError(true);
              setSnackOpen(true);
              // setIsActive(false);
            });
        } else {
          if (
            formData.identity_file === null ||
            formData.identity_file === undefined ||
            formData.identity_file === ""
          ) {
            setSnackMessage("Please choose a valid identification file");
            setIsSnackError(true);
            setSnackOpen(true);
          } else {
            let validExp = true;
            formData.employment.forEach((item) => {
              if (
                item.exp_certificate === null ||
                item.exp_certificate === undefined ||
                item.exp_certificate === ""
              ) {
                setSnackMessage(
                  `Please choose a valid experience certificate file for ${item.employer}`
                );
                setIsSnackError(true);
                setSnackOpen(true);
                validExp = false;
              }
            });

            if (validExp) {
              let validEduc = true;
              // formData.education.forEach((item) => {
              //   if (
              //     item.file_upload_certificate === null ||
              //     item.file_upload_certificate === undefined ||
              //     item.file_upload_certificate === ""
              //   ) {
              //     setSnackMessage(
              //       `Please choose a valid educational certificate file for ${item.examination_passed}`
              //     );
              //     setIsSnackError(true);
              //     setSnackOpen(true);
              //     validEduc = false;
              //   }
              // });
              if (validEduc) {
                if (formData?.conf1 && formData?.conf2) {
                  if (decOne.length === 0) {
                    if (formData?.photo) {
                      if (
                        formData?.reference_email1 ||
                        formData?.reference_email2
                      ) {
                        if (
                          formData?.reference_email1 &&
                          !emailRegex.test(formData?.reference_email1)
                        ) {
                          setSnackMessage(
                            `Please choose a valid email for reference 1`
                          );
                          setIsSnackError(true);
                          setSnackOpen(true);
                          return false;
                        }
                        if (
                          formData?.reference_email2 &&
                          !emailRegex.test(formData?.reference_email2)
                        ) {
                          setSnackMessage(
                            `Please choose a valid email for reference 2`
                          );
                          setIsSnackError(true);
                          setSnackOpen(true);
                          return false;
                        }
                        props.action(0, Data, "basicData");
                      } else {
                        props.action(0, Data, "basicData");
                      }
                    } else {
                      setSnackMessage(`Please check the disclaimers`);
                      setIsSnackError(true);
                      setSnackOpen(true);
                    }
                  } else {
                    setSnackMessage(
                      `Please check the emails provided for experience details`
                    );
                    setIsSnackError(true);
                    setSnackOpen(true);
                  }
                } else {
                  setSnackOpen(true);
                  setSnackMessage("Please check the disclaimers");
                  setIsSnackError(true);
                }
              }
            }
          }
        }
      else {
        setSnackOpen(true);
        setSnackMessage("Please enter a valid email");
        setIsSnackError(true);
      }
    } else {
      setSnackMessage(`Please Choose a valid Date of Birth`);
      setIsSnackError(true);
      setSnackOpen(true);
    }
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    // if (!flag) {
    //   handleEventList();
    // }
  };
  const handleOtherChange = (e) => {
    // let data = [...props.domainData];
    // data.forEach((item) => {
    //   item["checked"] = false;
    // });
    //
    // props.setDomain(data);
    setFormData({ ...formData, [e.target.name]: e.target.checked });
  };
  const handleDatePickerChange = (e, name) => {
    let date = e.value;
    const dateRegex = /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/;
    // console.log(date);
    // console.log(dateRegex.test(date));
    if (dateRegex.test(date)) {
      setErr(false);
    } else {
      setErr(true);
    }
    setFormData({ ...formData, [name]: date });
    setKeyvalue(keyvalue + 1);
  };
  const handleEducationalChange = (e, i) => {
    let data = { ...formData };

    if (e.target.name === "edu_type_parent") {
      const sub = qual.find((item) => item.name === e.target.value);
      setQualSub(sub?.children_recursive);
      data.education[i] = {
        ...data.education[i],
        [e.target.name]: e.target.value,
        edu_type_sub_one: "",
        edu_type_parent_other: e.target.value === "Other" ? "" : undefined,
      };
    } else if (e.target.name === "edu_type_sub_one") {
      const stream = qual
        .find((item) => item.name === data.education[i].edu_type_parent)
        .children_recursive.find((item) => item.name === e.target.value);
      setQualStream(stream?.children_recursive);
      const otherId = qual
        .find((item) => item.name === data.education[i].edu_type_parent)
        .children_recursive?.filter(
          (item) => item.name === e.target.value
        )[0]?.id;
      console.log(otherId);
      data.education[i] = {
        ...data.education[i],
        [e.target.name]: e.target.value,
        edu_type_sub_other_id: otherId,
        edu_type_others_text : "",
        edu_type_sub_one_other: e.target.value === "Other" ? "" : undefined,
        ...(otherId === 3 || otherId === 9
          ? {
              edu_type_sub_two: "Others",
            }
          : { edu_type_sub_two: "" }),
      };
    } else if (e.target.name === "edu_type_sub_two") {
      data.education[i] = {
        ...data.education[i],
        [e.target.name]: e.target.value,
        edu_type_others_text : "",
        edu_type_sub_two_other: e.target.value === "Other" ? "" : undefined,
      };
    } else if (e.target.name.includes("other")) {
      data.education[i] = {
        ...data.education[i],
        [e.target.name]: e.target.value,
      };
    } else {
      data.education[i] = {
        ...data.education[i],
        [e.target.name]: e.target.value,
      };
    }

    setFormData(data);
  };

  const uploadFile = (e) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "expert-manpower/profile-pic");
    // file.append("type", "expert-manpower/education-qualifications");

    common_file_upload(file)
      .then((res) => {
        setFormData({
          ...formData,
          photo: res.data,
          file_name: e.target.files[0].name,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const eduUploadFile = (e, i) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "expert-manpower/education-qualifications");
    common_file_upload(file)
      .then((res) => {
        let edu = formData.education;
        edu[i] = {
          ...edu[i],
          file_name: e.target.files[0].name,
          file_upload_certificate: res.data,
        };
        setFormData({
          ...formData,
          education: edu,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const expUploadFile = (e, i) => {
    let data = e.target.files[0];

    const file = new FormData();
    file.append("file", data);
    file.append("type", "expert-manpower/experience");
    common_file_upload(file)
      .then((res) => {
        let exp = formData.employment;
        exp[i] = {
          ...exp[i],
          file_name: e.target.files[0].name,
          exp_certificate: res.data,
        };
        setFormData({
          ...formData,
          employment: exp,
        });

        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage("Something went wrong");
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };

  const handleFileChange = (e) => {
    let size = e.target.files[0].size;

    let type = e.target.files[0].name.split(".").pop();

    if (type === "pdf") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 1) {
        // uploadFile(e);
        setFormData({
          ...formData,
          identity_file: e.target.files[0],
          file_name: e.target.files[0].name,
        });
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setFormData({
          ...formData,
          identity_file: null,
          file_name: null,
        });
      }
    } else {
      setSnackMessage("File should be in .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        identity_file: null,
        file_name: null,
      });
    }
  };
  const handleHandicappedFileChange = (e) => {
    let size = e?.target?.files[0]?.size;

    let type = e?.target?.files[0]?.name?.split(".")?.pop();

    if (type === "pdf") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 1) {
        // uploadFile(e);
        setFormData({
          ...formData,
          handicapped_file: e.target.files[0],
          handi_name: e.target.files[0].name,
        });
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setFormData({
          ...formData,
          handicapped_file: null,
          handi_name: null,
        });
      }
    } else {
      setSnackMessage("File should be in .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        handicapped_file: null,
        handi_name: null,
      });
    }
  };
  const handleNativeFileChange = (e) => {
    let file = e?.target?.files?.[0];
    if (file) {
      let size = file.size;
      let name = file.name;
      let type = name.split(".")?.pop();
      console.log(file);
      if (type === "pdf") {
        let mb = size / Math.pow(1024, 2);
        console.log(mb);

        if (mb < 1) {
          // uploadFile(e);

          setFormData({
            ...formData,
            non_keralite_file: file,
            nri_name: name,
          });
        } else {
          setSnackMessage("File size is greater than 1MB");
          setIsSnackError(true);
          setSnackOpen(true);
          setFormData({
            ...formData,
            non_keralite_file: null,
            nri_name: null,
          });
        }
      } else {
        setSnackMessage("File should be in .pdf format");
        setIsSnackError(true);
        setSnackOpen(true);
        setFormData({
          ...formData,
          non_keralite_file: null,
          nri_name: null,
        });
      }
    }
  };
  const handleCvFileChange = (e) => {
    let size = e.target.files[0].size;

    let type = e.target.files[0].name.split(".").pop();

    if (type === "pdf") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 1) {
        // uploadFile(e);
        setFormData({
          ...formData,
          cv_upload: e.target.files[0],
          cv_name: e.target.files[0].name,
        });
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setFormData({
          ...formData,
          cv_upload: null,
          cv_name: null,
        });
      }
    } else {
      setSnackMessage("File should be in .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        cv_upload: null,
        cv_name: null,
      });
    }
  };
  const handlePhotoChange = (e) => {
    let size = e.target.files[0].size;

    let type = e.target.files[0].name.split(".").pop();

    if (type === "png" || type === "jpeg" || type === "jpg") {
      let mb = size / Math.pow(1024, 2);

      if (mb < 1) {
        // uploadFile(e);
        setFormData({
          ...formData,
          photo: e.target.files[0],
          photo_name: e.target.files[0].name,
        });
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        setFormData({
          ...formData,
          photo: null,
          photo_name: null,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png format");
      setIsSnackError(true);
      setSnackOpen(true);
      setFormData({
        ...formData,
        photo: null,
        photo_name: null,
      });
    }
  };
  const handleEducationalFileChange = (e, i) => {
    let size = e.target.files[0].size;
    let type = e.target.files[0].name.split(".").pop();
    let edu = formData.education;
    if (
      type?.toLowerCase() === "png" ||
      type?.toLowerCase() === "jpeg" ||
      type?.toLowerCase() === "jpg" ||
      type?.toLowerCase() === "pdf"
    ) {
      let mb = size / Math.pow(1024, 2);
      if (mb < 1) {
        eduUploadFile(e, i);
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        edu[i] = {
          ...edu[i],
          file_name: null,
          file_upload_certificate: null,
        };
        setFormData({
          ...formData,
          education: edu,
        });
      }
    } else {
      setSnackMessage("File should be in .jpg / .jpeg / .png / .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      edu[i] = {
        ...edu[i],
        file_name: null,
        file_upload_certificate: null,
      };
      setFormData({
        ...formData,
        education: edu,
      });
    }
  };

  const handleExperienceFileChange = (e, i) => {
    let size = e.target.files[0].size;
    let exp = formData.employment;
    let type = e.target.files[0].name.split(".").pop();
    let data = e.target.files[0];
    if (type === "pdf") {
      let mb = size / Math.pow(1024, 2);
      if (mb < 1) {
        // expUploadFile(e, i);
        let exp = formData.employment;
        exp[i] = {
          ...exp[i],
          file_name: e.target.files[0].name,
          exp_certificate: data,
        };
        setFormData({
          ...formData,
          employment: exp,
        });
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        exp[i] = {
          ...exp[i],
          file_name: null,
          exp_certificate: null,
        };
        setFormData({
          ...formData,
          employment: exp,
        });
      }
    } else {
      setSnackMessage("File should be in .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      exp[i] = {
        ...exp[i],
        file_name: null,
        experience_certificate: null,
      };
      setFormData({
        ...formData,
        employment: exp,
      });
    }
  };

  const addeducationalQualifications = (i) => {
    let data = { ...formData };

    data.education.push({
      university: "",
      year: "",
      location: "",
      grade: "",
      gpa: "",
      percentage: "",
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeducationalQualifications = (i) => {
    let data = { ...formData };
    data.education.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const handleExperienceChange = (e, i) => {
    let data = { ...formData };
    data.employment[i] = {
      ...data.employment[i],
      [e.target.name]: e.target.value,
    };
    setFormData(data);
  };
  const handleCheckChange = (e, index) => {
    let data = { ...formData };

    // Update the checked status of the specific checkbox
    data.expertise_area[index].checked = e.target.checked;

    // Update the formData state
    setFormData(data);
  };
  const handleConfirmationChange = (e) => {
    let data = { ...formData };
    if (e.target.name == "conf1") {
      data.conf1 = e.target.checked;
    } else {
      data.conf2 = e.target.checked;
    }
    setFormData(data);
  };
  const handleExperticeChange = (e, i) => {
    let data = { ...formData };
    data.expertise_area[i] = {
      ...data.expertise_area[i],
      [e.target.name]: e.target.value,
    };
    setFormData(data);
  };
  const handleExpDatePickerChange = (e, i, name) => {
    let data = { ...formData };
    let date = e.value;
    const dateRegex = /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/;
    // console.log(date);
    // console.log(dateRegex.test(date));
    if (dateRegex.test(date)) {
      setErr(false);
    } else {
      setErr(true);
    }
    data.employment[i] = {
      ...data.employment[i],
      [name]: date,
    };
    setFormData(data);
    setKeyvalue(keyvalue + 1);
  };
  const handleexpCheckChange = (e, i) => {
    let data = { ...formData };
    // console.log(e);
    data.employment[i] = { ...data?.employment[i], working: e.target.checked };
    setFormData(data);
  };
  const addExperience = (i) => {
    let data = { ...formData };

    data.employment.push({
      employer: "",
      contact: "",
      address: "",
      from: "",
      to: "",
      email: "",
      reason: "",
      position: "",
      exp_certificate: null,
      working: false,
    });
    setFormData(data);
    setCount(count + 1);
  };
  const removeExperiance = (i) => {
    let data = { ...formData };
    data.employment.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const addExpertice = (i) => {
    let data = { ...formData };

    // data.expertise_area.push({
    //   expertise_name: "",
    // });
    setFormData(data);
    setCount(count + 1);
  };
  const removeExpertice = (i) => {
    let data = { ...formData };
    data.expertise_area.splice(i, 1);
    setCount(data);
    setCount(count + 1);
  };

  const handleValid = () => {
    let flags = false;
    if (props.areaOfInterest) {
      props.areaOfInterest.forEach((item) => {
        if (item.checked) flags = true;
      });
    }
    return flags;
  };
  const textFieldChange = (e) => {
    let data = formData;

    if (e.target.name === "interest_type") {
      if (e.target.value === "Administration") {
        data = {
          ...data,
          expertise_area: admin_type,
          [e.target.name]: e.target.value,
        };
      } else if (e.target.value === "Technical") {
        data = {
          ...data,
          expertise_area: technical_type,
          [e.target.name]: e.target.value,
        };
      } else if (e.target.value === "Management") {
        data = {
          ...data,
          expertise_area: management_type,
          [e.target.name]: e.target.value,
        };
      } else {
        data = {
          ...data,

          expertise_area: [],
          [e.target.name]: e.target.value,
        };
      }

      // setFormData(data)
    } else {
      data = {
        ...data,

        [e.target.name]: e.target.value,
      };
    }

    setFormData(data);
  };
  const numberChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    let data = formData;
    data = {
      ...data,

      [name]: newValue,
    };
    setFormData(data);
  };

  const handleEducationalNumberChange = (e, i) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    if (name === "percentage") {
      if (parseFloat(value) > 100) {
        value = "100";
      }
    }
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    // if (value.length <= 4) {
    data.education[i] = {
      ...data.education[i],
      [name]: newValue,
    };

    setFormData(data);
    // }
  };
  const handleEduFileChange = (e, i) => {
    let size = e.target.files[0].size;
    let exp = formData.education;
    let type = e.target.files[0].name.split(".").pop();
    let data = e.target.files[0];
    if (type === "pdf") {
      let mb = size / Math.pow(1024, 2);
      if (mb < 1) {
        // expUploadFile(e, i);
        let exp = formData.education;
        exp[i] = {
          ...exp[i],
          edu_cert_name: e.target.files[0].name,
          edu_certificate: data,
        };
        setFormData({
          ...formData,
          education: exp,
        });
      } else {
        setSnackMessage("File size is greater than 1MB");
        setIsSnackError(true);
        setSnackOpen(true);
        exp[i] = {
          ...exp[i],
          edu_cert_name: null,
          edu_certificate: null,
        };
        setFormData({
          ...formData,
          education: exp,
        });
      }
    } else {
      setSnackMessage("File should be in .pdf format");
      setIsSnackError(true);
      setSnackOpen(true);
      exp[i] = {
        ...exp[i],
        edu_cert_name: null,
        edu_certificate: null,
      };
      setFormData({
        ...formData,
        education: exp,
      });
    }
  };
  const handleNumberChange = (e) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9]/g, "");
    data = { ...data, [name]: newValue };
    setFormData(data);
  };
  const handlePinChange = (e) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9]/g, "");
    const regex = /^6\d{5}$/;
    // console.log(newValue);
    if (!newValue.startsWith(6)) {
      newValue = 6 + newValue.slice(0, 5); // Ensure it is a maximum of 6 characters long
    } else {
      newValue = newValue.slice(0, 6); // Ensure it is a maximum of 6 characters long
    }
    data = { ...data, [name]: newValue };
    if (regex.test(newValue)) {
      setPinErr(false);
    } else {
      setPinErr(true);
    }
    setFormData(data);
  };
  const handleExperiancelNumberChange = (e, i) => {
    let data = { ...formData };
    let name = e.target.name;
    let value = e.target.value;
    let newValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
    // if (value.length <= 4) {
    data.employment[i] = {
      ...data.employment[i],
      [name]: newValue,
    };

    setFormData(data);
    // }
  };

  const genderFieldChange = (e) => {
    let data = formData;
    data = {
      ...data,

      gender: e.target.value,
    };
    setFormData(data);
  };
  const markFieldChange = (e, i) => {
    // let data = { ...formData, education : formData?.education.map((item, j)  => i === j ? {...item, edu_qualification_type : e.target.value } : item) }
    // data.education[i] = {
    //   ...data.education[i],
    //   edu_qualification_type: e.target.value,
    // };
    // console.log(i, e.target)
    setFormData((f) => ({
      ...f,
      education: f?.education.map((item, j) =>
        i === j ? { ...item, edu_qualification_type: e.target.value } : item
      ),
    }));
    setUpdateKey((k) => k + 1);
  };
  const nativeFieldChange = (e) => {
    let data = formData;
    data = {
      ...data,

      native_kerala: Number(e.target.value),
    };
    setFormData(data);
  };
  const nrinativeFieldChange = (e) => {
    let data = formData;
    data = {
      ...data,

      nri_native: Number(e.target.value),
    };
    setFormData(data);
  };
  // const selectFieldChange = (e) => {
  //   let value = e.target.value;
  //   const selectedCat = props.category?.find(({ uuid }) => uuid === value);

  //   let data = formData;
  //   data = {
  //     ...data,

  //     category: value,
  //     category_name: selectedCat.cat_name,
  //   };
  //   setFormData(data);
  // };
  console.log(formData, props.data);

  useEffect(() => {
    qualifications_list()
      .then((res) => {
        setQual(res.data);
        console.log(res);
      })
      .catch((err) => {
        setQual([]);
        setSnackMessage(
          `Something went wrong. Please try to register after some time.`
        );
        setIsSnackError(true);
        setSnackOpen(true);
      });
  }, []);
  // useEffect(()=>{
  //   let data = formData?.education
  //   if(uKey === 0 && formData?.education){

  //   data?.forEach((item, i) => {
  //     if (item?.edu_type_parent) {
  //       console.log(item?.edu_type_parent)
  //       console.log(qual)
  //       const sub = qual.find((item2) =>  item2.name === item?.edu_type_parent);
  //       console.log(sub)
  //       setQualSub(sub?.children_recursive);
  //       console.log(sub?.children_recursive)

  //     }
  //     if (item?.edu_type_sub_one) {
  //       const stream = qualSub.find((item2) => item2.name === item?.edu_type_sub_one);
  //       setQualStream(stream?.children_recursive);

  //     }
  //   })
  // setUKey(1)
  // }

  // },[formData, qual, qualSub, uKey])
  console.log(qual, qualSub);
  console.log(formData);
  return (
    <div className="expert-man-power">
      <Loader open={isActive} />
      <form onSubmit={handlepreview}>
        <div className="col-xs-12 col-sm-12 mb-3">
          <div className="header-label">Are you a Native of Kerala? </div>
          <select
            className="form-select selectbox mandatory-select "
            required
            name="native_kerala"
            value={formData?.native_kerala ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option>-- Select --</option>
            <option
              value={1}
              // select={formData?.category_name === item.cat_name}
            >
              Yes
            </option>
            <option
              value={0}
              // select={formData?.category_name === item.cat_name}
            >
              No
            </option>
          </select>
        </div>
        {formData?.native_kerala == 0 && (
          <div className="col-xs-12 col-sm-12 mb-3">
            <div className="header-label">
              Are you a Non Resident Keralite?{" "}
            </div>
            <select
              className="form-select selectbox mandatory-select "
              required
              name="category"
              value={formData?.nri_kerala ?? ""}
              aria-label="Default select example"
              // onChange={(e) => selectFieldChange(e)}
              onChange={(e) => textFieldChange(e)}
            >
              <option
                value={1}
                // select={formData?.category_name === item.cat_name}
              >
                Yes
              </option>
              {/* <option
            value={false}
            // select={formData?.category_name === item.cat_name}
          >
            No
          </option> */}
            </select>
            <div className="file-up-helper " style={{ paddingTop: "12px" }}>
              Registration for Job Seeker is only applicable for Keralites and
              Non Resident Keralites. Application from Non Keralites will be
              rejected.
            </div>
          </div>
        )}
        {formData?.native_kerala == 0 && (
          <>
            <div className="col-xs-12 col-sm-12 mb-3">
              <div className="header-label mb-0">Proof of Native Address</div>
              <select
                className="form-select selectbox mandatory-select "
                required
                name="native_address_type"
                value={formData?.native_address_type ?? ""}
                aria-label="Default select example"
                // onChange={(e) => selectFieldChange(e)}
                onChange={(e) => textFieldChange(e)}
              >
                <option value="">-- Select--</option>
                <option value="Birth Certificate">Birth Certificate</option>
                <option value="SSLC Certificate">SSLC Certificate</option>
                <option value="Ration Card">Ration Card</option>
                <option value="Aadhar (Parent's)">Aadhaar (Parent's)</option>
              </select>
            </div>

            <div className="col-12">
              <CommonForm
                fieldType="file"
                label="Upload a Copy of Proof of Native Address"
                name="non_keralite_file"
                {...props}
                id="non_keralite_file"
                fileName={formData?.nri_name ?? formData?.non_keralite_file}
                //filename={formData.file_name}
                accept=".pdf"
                type="file"
                variant="outlined"
                onChange={handleNativeFileChange}
              />
              <div className="file-up-helper">
                *Upload only .pdf format and Size should be less than 1MB
              </div>
            </div>
          </>
        )}

        <div className="col-xs-12 col-sm-12 mb-3">
          <div className="header-label mb-0">
            Type of Id and Local Address Proof
          </div>
          <select
            className="form-select selectbox mandatory-select "
            required
            name="identity_type"
            value={formData?.identity_type ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option>-- Select--</option>
            <option value="Aadhaar">Aadhaar</option>
            <option value="Pan">Pan</option>
            <option value="Driving license">Driving license</option>
            <option value="Passport">Passport</option>
            <option value="Election id">Election id</option>
            <option value="OCI(Overseas Citizenship of India)">
              OCI(Overseas Citizenship of India)
            </option>
          </select>
        </div>
        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="text"
            required
            maxLength={20}
            name="identity_no"
            label="Identity No "
            value={formData.identity_no ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
        </div>
        <div className="col-12">
          <CommonForm
            fieldType="file"
            label="Upload ID proof with local address"
            name="identity_file"
            {...props}
            id="identity_file"
            fileName={formData?.file_name ?? formData?.identity_file}
            //filename={formData.file_name}
            accept=".pdf"
            type="file"
            variant="outlined"
            onChange={handleFileChange}
          />
          <div className="file-up-helper">
            *Upload only .pdf format and Size should be less than 1MB
          </div>
        </div>

        {/* <div className="col-12">
          <CommonForm
            fieldType="file"
            label="Upload  Identication Proof"
            name="non_keralite_file"
            {...props}
            id="non_keralite_file"
            fileName={formData?.file_name ?? formData?.non_keralite_file}
            //filename={formData.file_name}
            accept=".pdf"
            type="file"
            variant="outlined"
            onChange={handleFileChange}
          />
          <div className="file-up-helper">
            *Upload only .pdf format and Size should be less than 1MB
          </div>
        </div> */}

        <div className="col-xs-12 col-sm-12 mb-3">
          <div className="header-label">Are you a fresher? </div>
          <select
            className="form-select selectbox mandatory-select "
            required
            name="fresher"
            value={formData?.fresher ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option>-- Select --</option>
            <option
              value="Yes"
              // select={formData?.category_name === item.cat_name}
            >
              Yes
            </option>
            <option
              value="No"
              // select={formData?.category_name === item.cat_name}
            >
              No
            </option>
          </select>
        </div>
        <div className="col-xs-12 col-sm-12 mb-3">
          <CommonForm
            fieldType="text"
            label="Name (as per Aadhaar)"
            required
            name="fname"
            disabled={props.update}
            value={formData?.fname ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
        </div>
        {/* <div className="d-flex flex-col align-items-end col-12 ">
          <div className="col-12   mb-3">
            <label className={`control-label mylabel mb-0`}>
              Name (as per Aadhaar)
            </label>
            <select
              id="prefix"
              className="form-select  "
              required
              label="prefix"
              name="prefix"
              disabled={props.update}
              defaultValue={"Mr"}
              onChange={(e) => textFieldChange(e)}
              value={formData.prefix ? formData.prefix : "Mr"}
            >
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
              <option value="Dr">Dr</option>
            </select>
          </div>
          <div className="px-1"></div>
          <div className="d-flex col-12">
            <div className="col-12 ">
              <CommonForm
                fieldType="text"
                required
                name="fname"
                placeholder="Name"
                value={formData.fname ?? ""}
                onChange={(e) => {
                  textFieldChange(e);
                }}
                {...props}
              />
            </div>
          </div>
        </div> */}
        <div className="row mb-3">
          <div className="col-xs-12 col-sm-6">
            <CommonForm
              fieldType="date"
              label={`Date of Birth `}
              required
              name="dob"
              // minDate={minDate}
              // maxDate={maxDate}
              maxDate={new Date(eighteenYearsAgo)}
              minDate={new Date(sYearsAgo)}
              value={formData?.dob ?? ""}
              onChange={(e) => {
                handleDatePickerChange(e, "dob");
              }}
              {...props}
            />
            {err && (
              <div className="file-up-helper mt-1">
                Please select a valid date
              </div>
            )}
          </div>

          <div className="col-xs-12 col-sm-6 mt-2 d-flex justify-content-start align-items-center pt-3 ">
            <div className="control-label ">
              <b>Gender</b> :{" "}
            </div>
            <div className="d-flex ">
              <CommonForm
                fieldType="radio"
                label="Male"
                required
                name="gender"
                value="Male"
                checked={formData?.gender === "Male"}
                onChange={(e) => genderFieldChange(e)}
                {...props}
              />
              <CommonForm
                fieldType="radio"
                label="Female"
                required
                name="gender"
                checked={formData?.gender === "Female"}
                value="Female"
                onChange={(e) => genderFieldChange(e)}
                {...props}
              />
              <CommonForm
                fieldType="radio"
                label="Transqueer"
                required
                name="gender"
                checked={formData?.gender === "Transgender"}
                value="Transgender"
                onChange={(e) => genderFieldChange(e)}
                {...props}
              />
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 mb-3">
          <div className="header-label mb-0">Marital Status</div>
          <select
            className="form-select selectbox mandatory-select "
            required
            name="marital_status"
            value={formData?.marital_status ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option>-- Select --</option>
            <option
              value="Married"
              // select={formData?.category_name === item.cat_name}
            >
              Married
            </option>
            <option
              value="Unmarried"
              // select={formData?.category_name === item.cat_name}
            >
              Unmarried
            </option>
            <option
              value="Widowed"
              // select={formData?.category_name === item.cat_name}
            >
              Widowed
            </option>
            <option
              value="Separated"
              // select={formData?.category_name === item.cat_name}
            >
              Separated
            </option>
          </select>
        </div>
        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="text"
            required
            name="parent_name"
            label="Father/Spouse Name"
            placeholder="Father/Spouse Name"
            value={formData.parent_name ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
        </div>
        <div className="col-xs-12 col-sm-12 mb-3">
          <div className="header-label mb-0">Category</div>
          <select
            className="form-select selectbox mandatory-select "
            required
            name="caste"
            value={formData?.caste ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option>--Select--</option>
            <option
              value="General"
              // select={formData?.category_name === item.cat_name}
            >
              General
            </option>
            <option
              value="OBC"
              // select={formData?.category_name === item.cat_name}
            >
              OBC
            </option>
            <option
              value="SC"
              // select={formData?.category_name === item.cat_name}
            >
              SC
            </option>
            <option
              value="ST"
              // select={formData?.category_name === item.cat_name}
            >
              ST
            </option>
          </select>
        </div>
        <div className="col-xs-12 col-sm-12 mb-3">
          <div className="header-label mb-0">Are you Differently Abled?</div>
          <select
            className="form-select selectbox mandatory-select "
            name="handicapped"
            value={formData?.handicapped ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option>-- Select --</option>
            <option
              value="No"
              // select={formData?.category_name === item.cat_name}
            >
              No
            </option>
            <option
              value="Yes"
              // select={formData?.category_name === item.cat_name}
            >
              Yes
            </option>
          </select>
        </div>
        {formData?.handicapped == "Yes" && (
          <div className="col-12">
            <CommonForm
              fieldType="file"
              label="Upload  Disability Certificate"
              name="handicapped_file"
              {...props}
              id="handicapped_file"
              fileName={formData?.handi_name ?? formData?.handicapped_file}
              //filename={formData.file_name}
              accept=".pdf"
              type="file"
              variant="outlined"
              onChange={handleHandicappedFileChange}
            />
            <div className="file-up-helper">
              *Upload only .pdf format and Size should be less than 1MB
            </div>
          </div>
        )}
        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="email"
            label="Email Id"
            disabled={props.update}
            value={formData?.email ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            required
            name="email"
            {...props}
          />
        </div>
        <label className="control-label mylabel mb-2">Phone Number</label>
        <div className="d-flex col-12 align-items-end phone-side">
          <div className="col-1 ">
            <CommonForm
              fieldType="text"
              // name="alt_code"
              maxLength="4"
              value={91}
              disabled
            />
          </div>
          <div className="p-1"></div>
          <div className="col-11">
            <CommonForm
              fieldType="text"
              // label="Phone Number"
              disabled
              value={formData?.mobile}
              required
              name=""
              {...props}
            />
          </div>
        </div>
        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="email"
            label="Alternate Email Id"
            value={formData?.alt_email ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            name="alt_email"
            {...props}
          />
        </div>
        {/* <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="text"
            label="Phone Number"
            disabled
            value={formData?.mobile}
            required
            name=""
            {...props}
          />
        </div> */}
        <label className="control-label mylabel mb-2">
          {formData?.native_kerala == 0
            ? "NRI Phone Number"
            : "Alternate Phone"}
        </label>
        <div className="d-flex col-12 align-items-end phone-side">
          <div className="col-1 ">
            <CommonForm
              fieldType="text"
              name="alt_code"
              maxLength="4"
              value={formData.alt_code ?? ""}
              onChange={(e) => {
                handleNumberChange(e);
              }}
              // required = {formData?.native_kerala == 0 ? true : false}
              {...props}
            />
          </div>
          <div className="p-1"></div>
          <div className="col-11">
            <CommonForm
              fieldType="text"
              name="alt_contact"
              maxLength="10"
              value={formData.alt_contact ?? ""}
              // required = {formData?.native_kerala == 0 ? true : false}
              onChange={(e) => {
                handleNumberChange(e);
              }}
              {...props}
            />
          </div>
        </div>

        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="text"
            label="Address"
            multiline
            required
            className="no-margin"
            name="address"
            value={formData?.address ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
        </div>
        <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="text"
            label="Pin Code"
            maxLength="6"
            minLength="6"
            value={formData?.pin_code}
            required
            onChange={(e) => {
              handlePinChange(e);
            }}
            name="pin_code"
            {...props}
          />
          {pinErr && (
            <div className="file-up-helper pt-1">Please enter a valid pin</div>
          )}
        </div>
        {/* <div className="col-xs-12 col-sm-12">
          <CommonForm
            fieldType="text"
            required
            name="blood_group"
            placeholder="Blood group"
            label="Blood Group"
            value={formData.blood_group ?? ""}
            onChange={(e) => {
              textFieldChange(e);
            }}
            {...props}
          />
        </div> */}
        <div className="col-xs-12 col-sm-12 mb-3">
          <div className="header-label mb-0">Blood Group</div>
          <select
            className="form-select selectbox mandatory-select "
            required
            name="blood_group"
            value={formData?.blood_group ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option>-- Select--</option>
            <option value="O+">O+</option>
            <option value="O-">O-</option>
            <option value="A+">A+</option>
            <option value="A-">A-</option>
            <option value="AB+">AB+</option>
            <option value="AB-">AB-</option>
            <option value="B+">B+</option>
            <option value="B-">B-</option>
            <option value="Other">Other</option>
          </select>
        </div>
        {formData?.blood_group === "Other" && (
          <div className="col-12 ">
            <CommonForm
              fieldType="text"
              required
              label="Specify the Blood Group"
              name="other_blood_group"
              placeholder="Specify the blood group"
              value={formData.other_blood_group ?? ""}
              onChange={(e) => {
                textFieldChange(e);
              }}
              {...props}
            />
          </div>
        )}
        <div className="col-12">
          <CommonForm
            fieldType="file"
            label="Upload Photo"
            name="photo"
            {...props}
            id="photo"
            // fileName={formData?.photo_name}
            fileName={formData?.photo_name ?? formData?.photo}
            //filename={formData.file_name}
            accept=".jpg,.jpeg,.png"
            type="file"
            variant="outlined"
            onChange={handlePhotoChange}
          />
          <div className="file-up-helper">
            *Upload only .jpg , .jpeg, .png , format and Size should be less
            than 1MB
          </div>
        </div>
        <div className="col-12">
          <CommonForm
            fieldType="file"
            label="Upload your CV"
            name="cv_upload"
            {...props}
            id="cv_upload"
            fileName={formData?.cv_name ?? formData?.cv_upload}
            //filename={formData.file_name}
            accept=".pdf"
            type="file"
            variant="outlined"
            onChange={handleCvFileChange}
          />
          <div className="file-up-helper">
            *Upload only .pdf format and Size should be less than 1MB
          </div>
        </div>

        <div className="header-label">
          Educational Qualification (To enter the details of 10th/12th please
          choose Qualification Type as Non-Technical.)
        </div>
        {formData.education?.map((e, i) => (
          <div className="section-datas-cont mb-3">
            <div className="section-Cont">
              {i === 0 && (
                <div className="header-label text-capitalize fw-bold">
                  Please enter your highest qualification first.
                </div>
              )}
              <div className="row">
                <>
                  {qual && qual.length !== 0 && (
                    <>
                      <div className="col-xs-12 col-sm-6">
                        <div className="header-label mb-1">
                          Qualification Type
                        </div>
                        <select
                          className="form-select selectbox mandatory-select "
                          required
                          name="edu_type_parent"
                          value={e?.edu_type_parent ?? ""}
                          aria-label="Default select example"
                          // onChange={(e) => selectFieldChange(e)}
                          onChange={(e) => {
                            handleEducationalChange(e, i);
                          }}
                        >
                          <option value="">-- Select--</option>
                          {qual?.map((item, i) => (
                            <option value={item.name} key={i}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {e?.edu_type_parent && (
                        <div className="col-xs-12 col-sm-6">
                          <div className="header-label mb-1">Qualification</div>
                          {/* {console.log(e.edu_type_parent, qualSub)} */}
                          <select
                            className="form-select selectbox mandatory-select "
                            required
                            name="edu_type_sub_one"
                            value={e?.edu_type_sub_one ?? ""}
                            aria-label="Default select example"
                            // onChange={(e) => selectFieldChange(e)}
                            onChange={(e) => handleEducationalChange(e, i)}
                          >
                            <option>-- Select --</option>
                            {/* { setQualSub(qual.find((item2) =>  item2.name === e?.edu_type_parent))} */}
                            {qual
                              .find(
                                (item2) => item2.name === e?.edu_type_parent
                              )
                              .children_recursive?.map(
                                (item, i) => (
                                  // item?.name !== "Others" && (
                                  <option value={item.name} key={i}>
                                    {item.name}
                                  </option>
                                )
                                // )
                              )}
                          </select>
                        </div>
                      )}

                      {e?.edu_type_sub_one &&
                        e?.edu_type_sub_one !== "PHD" &&
                        e?.edu_type_sub_one !== "Others" && (
                          <div className="col-xs-12 col-sm-6">
                            <div className="mb-1 mt-2">Stream</div>
                            <select
                              className="form-select selectbox mandatory-select "
                              required
                              name="edu_type_sub_two"
                              value={e?.edu_type_sub_two ?? ""}
                              aria-label="Default select example"
                              // onChange={(e) => selectFieldChange(e)}
                              onChange={(e) => handleEducationalChange(e, i)}
                            >
                              <option>-- Select --</option>
                              {qual
                                .find(
                                  (item2) => item2.name === e?.edu_type_parent
                                )
                                .children_recursive?.find(
                                  (item) => item.name === e?.edu_type_sub_one
                                )
                                .children_recursive?.map((item, i) => (
                                  <option value={item.name} key={i}>
                                    {item.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        )}
                      {((e?.edu_type_sub_two &&
                        e?.edu_type_sub_other_id &&
                        e?.edu_type_sub_two === "Others") ||
                        e?.edu_type_sub_one === "Others") && (
                        <div className="col-xs-12 col-sm-6 pt-2">
                          <CommonForm
                            fieldType="text"
                            required
                            label={`${
                              e?.edu_type_sub_other_id === 3 ||
                              e?.edu_type_sub_other_id === 9
                                ? "Specify the Thesis"
                                : "Specify the Degree"
                            }`}
                            name="edu_type_others_text"
                            placeholder={`${
                              e?.edu_type_sub_other_id === 3 ||
                              e?.edu_type_sub_other_id === 9
                                ? "Specify the Thesis"
                                : "Specify the Degree"
                            }`}
                            value={e.edu_type_others_text ?? ""}
                            onChange={(e) => {
                              handleEducationalChange(e, i);
                            }}
                            {...props}
                          />
                        </div>
                      )}
                    </>
                  )}
                </>
              </div>
              <br />
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <CommonForm
                    fieldType="text"
                    label="Board/University Name"
                    required
                    name="university"
                    value={e?.university}
                    onChange={(e) => {
                      handleEducationalChange(e, i);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-6">
                  <CommonForm
                    fieldType="text"
                    label="City/State"
                    required
                    name="location"
                    value={e?.location}
                    onChange={(e) => {
                      handleEducationalChange(e, i);
                    }}
                    {...props}
                  />
                </div>{" "}
                <div className="col-xs-12 col-sm-6">
                  <CommonForm
                    fieldType="text"
                    label="Year of Passing"
                    required
                    name="year"
                    minlength="4"
                    maxLength="4"
                    value={e?.year}
                    onChange={(e) => {
                      handleEducationalNumberChange(e, i);
                    }}
                    {...props}
                  />
                </div>{" "}
                <div className="col-xs-12 col-sm-6  d-flex justify-content-center align-items-center ">
                  <div className="w-100 d-flex ">
                    <CommonForm
                      fieldType="radio"
                      key={`${i}_mark_${updateKey}`}
                      label="Mark"
                      // required
                      name={`Mark${i}`}
                      value="Mark"
                      checked={
                        formData?.education[i].edu_qualification_type === "Mark"
                      }
                      onChange={(e) => markFieldChange(e, i)}
                      {...props}
                    />
                    <CommonForm
                      fieldType="radio"
                      label="Grade"
                      key={`${i}_grade_${updateKey}`}
                      // required
                      name={`Grade${i}`}
                      checked={
                        formData?.education[i].edu_qualification_type ===
                        "Grade"
                      }
                      value="Grade"
                      onChange={(e) => markFieldChange(e, i)}
                      {...props}
                    />
                    <CommonForm
                      fieldType="radio"
                      label="CGPA"
                      key={`${i}_cgpa_${updateKey}`}
                      // required
                      name={`CGPA${i}`}
                      checked={
                        formData?.education[i].edu_qualification_type === "CGPA"
                      }
                      value="CGPA"
                      onChange={(e) => markFieldChange(e, i)}
                      {...props}
                    />
                  </div>
                </div>
                {/* <div className="col-xs-12 col-sm-3">
                  <CommonForm
                    fieldType="text"
                    label="Grade"
                    required
                    minlength="1"
                    maxLength="4"
                    name="grade"
                    value={e?.grade}
                    onChange={(e) => {
                      handleEducationalChange(e, i);
                    }}
                    {...props}
                  />
                </div> */}
                {/* <div className="col-xs-12 col-sm-3">
                  <CommonForm
                    fieldType="text"
                    label="GPA"
                    required
                    minlength="1"
                    maxLength="4"
                    name="gpa"
                    value={e?.gpa}
                    onChange={(e) => {
                      handleEducationalNumberChange(e, i);
                    }}
                    {...props}
                  />
                </div> */}
                {formData?.education[i].edu_qualification_type && (
                  <div className="col-xs-12 col-sm-6">
                    <CommonForm
                      fieldType="text"
                      label={
                        formData?.education[i].edu_qualification_type === "CGPA"
                          ? "CGPA"
                          : formData?.education[i].edu_qualification_type ===
                            "Mark"
                          ? "Mark"
                          : formData?.education[i].edu_qualification_type ===
                            "Grade"
                          ? "Grade"
                          : ""
                      }
                      required
                      minLength="1"
                      maxLength="5"
                      name="edu_qualification_text"
                      value={e?.edu_qualification_text}
                      onChange={(e) => {
                        handleEducationalChange(e, i);
                      }}
                      {...props}
                    />
                  </div>
                )}
                <div className="col-xs-12 col-sm-6">
                  <CommonForm
                    fieldType="text"
                    label="Equivalent Percentage"
                    required
                    minlength="1"
                    maxLength="5"
                    name="percentage"
                    value={e?.percentage}
                    onChange={(e) => {
                      handleEducationalNumberChange(e, i);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-12 pt-2">
                  <CommonForm
                    fieldType="file"
                    // required={!e.edu_cert_name ? true : false}
                    label="Upload Certificate"
                    name="edu_certificate"
                    onChange={(e) => {
                      handleEduFileChange(e, i);
                    }}
                    {...props}
                    fileName={e?.edu_cert_name ?? e.edu_certificate}
                    //filename={e?.file_name}
                    id="edu_certificate"
                    accept=".pdf"
                    type="file"
                    variant="outlined"
                    // onChange={handleFileChange}
                  />
                  <div className="file-up-helper">
                    *Upload only .pdf format and Size should be less than 1MB
                  </div>
                </div>
              </div>
            </div>

            <div className="more-btn-cnt">
              {formData.education.length === i + 1 && (
                <button
                  style={{ marginLeft: 5 }}
                  type="button"
                  {...props}
                  className="btn btn-primary"
                  onClick={(e) => addeducationalQualifications(i)}
                >
                  Add More
                </button>
              )}
              {formData.education.length > 1 && (
                <button
                  {...props}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => removeducationalQualifications(i)}
                >
                  Remove
                </button>
              )}
            </div>
          </div>
        ))}
        {formData.fresher == "No" && (
          <>
            <div className="header-label">
              Employment Details (from current employment onwards)
            </div>
            {formData.employment?.map((e, i) => (
              <div className="section-datas-cont">
                <div className="section-Cont">
                  <div className="row">
                    <div className="col-xs-12 col-sm-6">
                      <CommonForm
                        fieldType="text"
                        label="Name of Organization"
                        required
                        name="employer"
                        value={e?.employer}
                        onChange={(e) => {
                          handleExperienceChange(e, i);
                        }}
                        {...props}
                      />
                    </div>{" "}
                    <div className="col-xs-12 col-sm-6">
                      <CommonForm
                        fieldType="text"
                        label="Contact No"
                        minlength="10"
                        maxLength="10"
                        name="contact"
                        value={e?.contact}
                        onChange={(e) => {
                          handleExperiancelNumberChange(e, i);
                        }}
                        {...props}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                      <CommonForm
                        fieldType="email"
                        label="Email Id"
                        required
                        name="email"
                        value={e?.email}
                        onChange={(e) => {
                          handleExperienceChange(e, i);
                        }}
                        {...props}
                      />
                    </div>{" "}
                    <div className="col-xs-12 col-sm-12">
                      <CommonForm
                        fieldType="text"
                        label="Address"
                        required
                        multiline
                        name="address"
                        value={e?.address}
                        onChange={(e) => {
                          handleExperienceChange(e, i);
                        }}
                        {...props}
                      />
                    </div>{" "}
                    <div className="col-xs-12 col-sm-6">
                      <CommonForm
                        fieldType="text"
                        label="Designation"
                        required
                        name="position"
                        value={e?.position}
                        onChange={(e) => {
                          handleExperienceChange(e, i);
                        }}
                        {...props}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6 d-flex justify-content-center pt-2">
                      <CommonForm
                        fieldType="checkbox"
                        value={e.working}
                        checked={e.working === true ? true : false}
                        onChange={(e) => handleexpCheckChange(e, i)}
                        label="Currently Working Here?"
                        {...props}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <CommonForm
                        fieldType="date"
                        label="From"
                        required
                        name="from"
                        maxDate={new Date()}
                        value={e?.from}
                        onChange={(e) => {
                          handleExpDatePickerChange(e, i, "from");
                        }}
                        {...props}
                      />
                    </div>
                    {(formData?.employment[i].working === "No" ||
                      !formData?.employment[i].working) && (
                      <div className="col-xs-12 col-sm-6">
                        <CommonForm
                          fieldType="date"
                          label="To"
                          required
                          name="to"
                          maxDate={new Date()}
                          value={e?.to}
                          onChange={(e) => {
                            handleExpDatePickerChange(e, i, "to");
                          }}
                          {...props}
                        />
                      </div>
                    )}
                    {(formData?.employment[i].working == "No" ||
                      !formData?.employment[i].working) && (
                      <div className="col-xs-12 col-sm-12 pt-3">
                        <CommonForm
                          fieldType="text"
                          label="Reason for Leaving"
                          multiline
                          required
                          value={e?.reason}
                          onChange={(e) => {
                            handleExperienceChange(e, i);
                          }}
                          className="no-margin"
                          name="reason"
                          {...props}
                        />
                      </div>
                    )}
                    <div className="col-12 pt-2">
                      <CommonForm
                        fieldType="file"
                        // required={!e.file_name ? true : false}
                        label={
                          formData?.employment[i].working === false
                            ? "Experience Certificate "
                            : "Appointment Order"
                        }
                        name="experience_certificate"
                        onChange={(e) => {
                          handleExperienceFileChange(e, i);
                        }}
                        {...props}
                        fileName={e?.file_name ?? e.exp_certificate}
                        //filename={e?.file_name}
                        id="experience_certificate"
                        accept=".pdf"
                        type="file"
                        variant="outlined"
                        // onChange={handleFileChange}
                      />
                      <div className="file-up-helper">
                        *Upload only .pdf format and Size should be less than
                        1MB
                      </div>
                    </div>
                  </div>
                </div>

                <div className="more-btn-cnt">
                  {formData.employment.length === i + 1 && (
                    <button
                      style={{ marginLeft: 5 }}
                      type="button"
                      {...props}
                      className="btn btn-primary"
                      onClick={(e) => addExperience(i)}
                    >
                      Add More
                    </button>
                  )}
                  {formData.employment.length > 1 && (
                    <button
                      {...props}
                      type="button"
                      className="btn btn-primary"
                      onClick={(e) => removeExperiance(i)}
                    >
                      Remove
                    </button>
                  )}
                </div>
              </div>
            ))}{" "}
          </>
        )}

        <div className="header-label">
          References (Please include the contact details of the person who can
          vouch for you. If you have reference details please fill all the
          fields, else keep all the fields blank and proceed.)
        </div>

        <div className="section-datas-cont mb-3">
          <div className="section-Cont">
            <div className=" d-flex justify-content-between">
              <div className="col-5">
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Name"
                    // required
                    name="reference_name1"
                    value={formData?.reference_name1 ?? ""}
                    onChange={(e) => {
                      textFieldChange(e);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Designation"
                    // required
                    name="reference_designation1"
                    value={formData?.reference_designation1 ?? ""}
                    onChange={(e) => {
                      textFieldChange(e);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Address"
                    // required
                    name="reference_address1"
                    value={formData?.reference_address1 ?? ""}
                    onChange={(e) => {
                      textFieldChange(e);
                    }}
                    {...props}
                  />
                </div>
                {/* <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Contact No"
                    required
                    maxLength="10"
                    minLength="10"
                    name="reference_contact1"
                    value={formData?.reference_contact1 ?? ""}
                    onChange={(e) => {
                      handleNumberChange(e);
                    }}
                    {...props}
                  />
                </div> */}

                <label className="control-label mylabel mb-1">Contact No</label>
                <div className="d-flex col-12 align-items-end phone-side">
                  <div className="col-3 ">
                    <CommonForm
                      fieldType="text"
                      name="ref_code_1"
                      maxLength="4"
                      value={formData.ref_code_1 ?? ""}
                      onChange={(e) => {
                        handleNumberChange(e);
                      }}
                      required={formData?.reference_contact1 ? true : false}
                      // required = {formData?.native_kerala == 0 ? true : false}
                      {...props}
                    />
                  </div>
                  <div className="p-1"></div>
                  <div className="col-9">
                    <CommonForm
                      fieldType="text"
                      // required
                      maxLength="10"
                      minLength="10"
                      name="reference_contact1"
                      value={formData?.reference_contact1 ?? ""}
                      onChange={(e) => {
                        handleNumberChange(e);
                      }}
                      {...props}
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Email Id"
                    // required
                    name="reference_email1"
                    value={formData?.reference_email1 ?? ""}
                    onChange={(e) => {
                      textFieldChange(e);
                    }}
                    {...props}
                  />
                </div>
              </div>
              <div className="p-1 border-end"></div>
              <div className=" col-5">
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Name"
                    // required
                    name="reference_name2"
                    value={formData?.reference_name2 ?? ""}
                    onChange={(e) => {
                      textFieldChange(e);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Designation"
                    // required
                    name="reference_designation2"
                    value={formData?.reference_designation2 ?? ""}
                    onChange={(e) => {
                      textFieldChange(e);
                    }}
                    {...props}
                  />
                </div>
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Address"
                    // required
                    name="reference_address2"
                    value={formData?.reference_address2 ?? ""}
                    onChange={(e) => {
                      textFieldChange(e);
                    }}
                    {...props}
                  />
                </div>
                {/* <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Contact No"
                    required
                    maxLength="10"
                    minLength="10"
                    name="reference_contact2"
                    value={formData?.reference_contact2 ?? ""}
                    onChange={(e) => {
                      handleNumberChange(e);
                    }}
                    {...props}
                  />
                </div> */}
                <label className="control-label mylabel mb-1">Contact No</label>
                <div className="d-flex col-12 align-items-end phone-side">
                  <div className="col-3 ">
                    <CommonForm
                      fieldType="text"
                      name="ref_code_2"
                      maxLength="4"
                      value={formData.ref_code_2 ?? ""}
                      onChange={(e) => {
                        handleNumberChange(e);
                      }}
                      required={formData?.reference_contact2 ? true : false}
                      // required = {formData?.native_kerala == 0 ? true : false}
                      {...props}
                    />
                  </div>
                  <div className="p-1"></div>
                  <div className="col-9">
                    <CommonForm
                      fieldType="text"
                      // required
                      maxLength="10"
                      minLength="10"
                      name="reference_contact2"
                      value={formData?.reference_contact2 ?? ""}
                      onChange={(e) => {
                        handleNumberChange(e);
                      }}
                      {...props}
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12">
                  <CommonForm
                    fieldType="text"
                    label="Email Id"
                    // required
                    name="reference_email2"
                    value={formData?.reference_email2 ?? ""}
                    onChange={(e) => {
                      textFieldChange(e);
                    }}
                    {...props}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 mb-3">
          <div className="header-label">Area of Interest</div>
          <select
            className="form-select selectbox mandatory-select "
            required
            name="interest_type"
            value={formData?.interest_type ?? ""}
            aria-label="Default select example"
            // onChange={(e) => selectFieldChange(e)}
            onChange={(e) => textFieldChange(e)}
          >
            <option value="">--Select--</option>
            <option value="Administration">Administration</option>
            <option value="Technical">Technical</option>
            <option value="Management">Management</option>
          </select>
        </div>
        {formData?.interest_type && (
          <div>
            <div className="header-label">Preferences</div>
            <div className="check-box-cont mb-2">
              {/* {formData.interest_type === "Administration" &&
                admin_type.map((item, index) => (
                  <CommonForm
                    fieldType="checkbox"
                    value={item.checked}
                    // required={!formData.any_other_flag && !handleValid()}
                    checked={item.checked}
                    onChange={(e) =>
                      handleCheckChange(e, index, item?.domain_name)
                    }
                    label={item.domain_name}
                    {...props}
                  />
                ))} */}
              {/* {formData.interest_type === "Technical" &&
                technical_type.map((item, index) => (
                  <CommonForm
                    fieldType="checkbox"
                    value={item.checked}
                    // required={!formData.any_other_flag && !handleValid()}
                    checked={item.checked}
                    onChange={(e) =>
                      handleCheckChange(e, index, item?.domain_name)
                    }
                    label={item.domain_name}
                    {...props}
                  />
                ))} */}
              {/* {formData.interest_type === "Management" &&
                management_type.map((item, index) => (
                  <CommonForm
                    fieldType="checkbox"
                    value={item.checked}
                    // required={!formData.any_other_flag && !handleValid()}
                    checked={item.checked}
                    onChange={(e) =>
                      handleCheckChange(e, index, item?.domain_name)
                    }
                    label={item.domain_name}
                    {...props}
                  />
                ))} */}
              {formData.interest_type &&
                formData?.expertise_area.map((item, index) => (
                  <CommonForm
                    fieldType="checkbox"
                    value={item.checked}
                    checked={item.checked}
                    onChange={(e) => handleCheckChange(e, index)}
                    label={item.domain_name}
                    {...props}
                  />
                ))}

              <CommonForm
                fieldType="checkbox"
                onChange={handleOtherChange}
                label="Any Other"
                name="any_other_flag"
                checked={formData.any_other_flag}
                value={formData.any_other_flag}
                {...props}
              />
            </div>
            {/* if any other field checked ,below is mandatory */}
            {formData.any_other_flag && (
              <div>
                <CommonForm
                  fieldType="text"
                  placeholder="Please specify the Domain.."
                  required
                  maxLength={50}
                  name="any_other_domain"
                  value={formData.any_other_domain ?? ""}
                  onChange={(e) => {
                    textFieldChange(e);
                  }}
                  {...props}
                />
              </div>
            )}
            {/* ////////////////////////////// */}
          </div>
        )}
        {/* {formData.expertise_area?.map((e, i) => (
          <div className="section-datas-cont"> */}
        {/* <div className="col-xs-12 col-sm-12"> */}
        {/* <CommonForm
              fieldType="text"
              label="Area of Expertise"
              required
              value={e?.expertise_name}
              onChange={(e) => {
                handleExperticeChange(e, i);
              }}
              className="no-margin"
              name="expertise_name"
              {...props}
            /> */}
        {/* </div> */}

        {/* <div className="more-btn-cnt">
              {formData.expertise_area.length === i + 1 && (
                <button
                  style={{ marginLeft: 5 }}
                  type="button"
                  {...props}
                  className="btn btn-primary"
                  onClick={(e) => addExpertice(i)}
                >
                  Add More
                </button>
              )}
              {formData.expertise_area.length > 1 && (
                <button
                  {...props}
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => removeExpertice(i)}
                >
                  Remove
                </button>
              )}
            </div>
          </div>
        ))} */}

        {/* <div className="fieldset">
          <div className="header-label">Any other area of interest</div>
          <div
          // className="check-box-cont"
          >
            <div className="p-10 check-box-cont">
              {" "}
              {props.areaOfInterest?.map((item, index) => (
                <CommonForm
                  fieldType="checkbox"
                  value={item.uuid}
                  checked={item.checked}
                  required={!handleValid()}
                  onChange={(e) => handleCheckChange(e, index)}
                  label={item.area_of_interest_name}
                  {...props}
                />
              ))}
            </div>
          </div>
        </div> */}

        {/* <div className="col-12">
          <CommonForm
            fieldType="file"
            required={!formData?.file_name ? true : false}
            label="Upload  your Photo"
            name="photo"
            {...props}
            id="photo"
            fileName={formData?.file_name}
            //filename={formData.file_name}
            accept=".jpg,.jpeg,.png"
            type="file"
            variant="outlined"
            onChange={handleFileChange}
          />
          <div className="file-up-helper">
            *Upload only .jpg , .jpeg, .png , format and Size should be less
            than 1MB
          </div>
        </div> */}

        <div className="mt-3">
          <CommonForm
            fieldType="checkbox"
            onChange={(e) => handleConfirmationChange(e)}
            label="I certify that the above information I mentioned are true and to the best of my knowledge"
            name="conf1"
            checked={formData.conf1}
            value={formData.conf1}
            {...props}
          />
          <CommonForm
            fieldType="checkbox"
            onChange={(e) => handleConfirmationChange(e)}
            label="I understand that all the information in the application are captured to create a job seeker registry."
            name="conf2"
            checked={formData?.conf2}
            value={formData?.conf2}
            {...props}
          />
        </div>

        <div className="footer-buttons">
          <button type="submit" className="primary-btn">
            SUBMIT TO PREVIEW
          </button>
        </div>
      </form>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />
    </div>
  );
}
