import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import CustomTable from "../../../../components/table/table";
import ResourseCard from "../../../../components/page/tiles/resourcecard";
import {
  candidate_download,
  interview_candidate_details,
  job_candidate_details,
  job_confirm,
  job_joined,
  job_payment,
  pdf_download,
  user_get_candidate_details,
  user_get_downloaded_candidate_details,
  user_get_manpower_details,
} from "../../user.api";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "../style.css";
import BreadCrumbsComp from "../../../../components/navigator/breadcrumbs";
import Loader from "../../../../components/loader/loader";
import { useReactToPrint } from "react-to-print";
import PaymentModal from "../../candidates/details/paymentModal";
import PaymentDetailsModal from "./paymentModal";
import Swal from "sweetalert2";

let count = 100;
export default function ResourcePersonalDetails(props) {
  const [data, setData] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [print, setPrint] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const selectedParam = useParams();
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);
  const pageRef = useRef(null);
  const getDetails = (id) => {
    user_get_downloaded_candidate_details(id).then((res) => {
      let tdata = res.data;

      setData(tdata);
      setIsActive(false);
    });
  };
  useEffect(() => {
    getDetails(selectedParam.id);
  }, []);
  const handleDownloadIdCert = (data) => {
    let path = `${data}`;
    window.open(path, "_blank");
  };
  // const handleCompanyDownload = () => {
  //   const payload = {
  //     candidate_id: selectedParam.id,
  //   };
  //   candidate_download(payload).then(() => {});
  // };

  // const handleDownload = async () => {
  //   // handleCompanyDownload();
  //   const element = pageRef.current;
  //   const canvas = await html2canvas(element);
  //   const data = canvas.toDataURL("image/png");

  //   const pdf = new jsPDF("p", "mm", "a4");
  //   const imgProps = pdf.getImageProperties(data);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width ;

  //   pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
  //   pdf.save("download.pdf");
  // };
  // const handleDownload = useReactToPrint({
  //   content: () => pageRef.current,
  //   documentTitle: "Downloaded Candidate Details",
  //   // onAfterPrint: () => alert("Print success!"),
  // });
  const handleDownload = () => {
    pdf_download(selectedParam.id).then((res) => {
      // console.log(res)
      window.open(res.pdf, "_blank");
    });

  };
  const handleInterview = (id) => {
    setIsActive(true);

    interview_candidate_details(id).then((res) => {
      getDetails(selectedParam.id);
      setIsActive(false);
    });
    //   }
    // });
  };
  const handleConfirm = (id) => {
    setIsActive(true);
    Swal.fire({

      inputLabel: "Message",
      input: "textarea",
      showCancelButton: false,
      confirmButtonColor: "#0A223D",
      confirmButtonText: "Submit",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      inputValidator: (value) => {
        if (!value) {
          return "Please describe the reason";
        }
      },
      showCancelButton: true,
    }).then((message) => {
      if (message.isConfirmed === true) {
        let mdata = {
          appointment_remarks: message?.value ?? "",
        };
        job_confirm(id, mdata).then((res) => {
          getDetails(selectedParam.id);
          setIsActive(false);
        });
      }
    });
  };

  const handleBack = () => {

    props.history.push("/candidate-profiles");
  }

  const handleJoined = (id) => {
    setIsActive(true);
    Swal.fire({
      inputLabel: "Message",
      input: "textarea",
      showCancelButton: false,
      confirmButtonColor: "#0A223D",
      confirmButtonText: "Submit",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      inputValidator: (value) => {
        if (!value) {
          return "Please describe the reason";
        }
      },
      showCancelButton: true,
    }).then((message) => {
      if (message.isConfirmed === true) {
        let mdata = {
          join_remarks: message?.value ?? "",
        };
        job_joined(id, mdata).then((res) => {
          getDetails(selectedParam.id);
          setIsActive(false);
          setDetailsOpen(true);
        });
      }
    });
  };
  // const handlePayment = (id, data) => {
  //   setIsActive(true);
  //   Swal.fire({
  //     inputLabel: "Message",
  //     input: "textarea",

  //     showCancelButton: false,
  //     confirmButtonColor: "#0A223D",
  //     confirmButtonText: "Submit",
  //     inputPlaceholder : "Please mention the Transaction Id, Date and Amount along with the message.",
  //     inputAttributes: {
  //       "aria-label": "Type your message here",
  //     },
  //     text : "Please mention the Transaction Id, Date and Amount along with the message.",
  //     customClass: {
  //       popup: 'swal-left-align'  // Apply a custom CSS class
  //   },
  //     inputValidator: (value) => {
  //       if (!value) {
  //         return "Please describe the reason";
  //       }
  //     },
  //     showCancelButton: true,
  //   }).then((message) => {
  //     if (message.isConfirmed === true) {
  //       let mdata = {
  //         payment_remarks: message?.value ?? "",
  //       };
  //       job_payment(id, mdata).then((res) => {
  //         getDetails(selectedParam.id);
  //         setIsActive(false);
  //       });
  //     }
  //   });
  // };

  const handlePayment = (id, data) => {
    setIsActive(true);

    Swal.fire({
      title: `<div style="font-size: 18px; display:flex; justify-content: center">Please fill below details before submittion</div>`,
      html: `
        <div class="payment-new">
        <div class="payment-details-filed">
        <label>Company Name</label>
          <input type="text" id="company-name" class="swal2-input" >
        </div>
            <div class="payment-details-filed">
          <label>
          Transaction ID
          </label>
          <input type="text" id="transaction-id" class="swal2-input" >
          </div>
             <div class="payment-details-filed">
          <label>
          Transaction Date
          </label>
          <input type="date" id="transaction-date" class="swal2-input">
          </div>
           <div class="payment-details-filed">
         <label>
         Amount
         </label>
          <input type="number" id="amount" class="swal2-input" >
         </div>
           <div class="payment-details-filed">
        <label>
        Method of Payment
        </label>
          <input type="text" id="payment-method" class="swal2-input" >
        </div>
        </div>
      `,
      confirmButtonColor: "#0A223D",
      confirmButtonText: "Submit",
      showCancelButton: true,
      focusConfirm: false,
      preConfirm: () => {
        const companyName = document.getElementById("company-name").value;
        const transactionId = document.getElementById("transaction-id").value;
        const transactionDate = document.getElementById("transaction-date").value;
        const amount = document.getElementById("amount").value;
        const paymentMethod = document.getElementById("payment-method").value;

        if (!companyName || !transactionId || !transactionDate || !amount || !paymentMethod) {
          Swal.showValidationMessage("Please fill out all fields");
        } else {
          return {
            companyName,
            transactionId,
            transactionDate,
            amount,
            paymentMethod,
          };
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const paymentData = {
          company_name: result.value.companyName, // Match backend key
          transaction_id: result.value.transactionId, // Match backend key
          transaction_date: result.value.transactionDate, // Match backend key
          amount: result.value.amount, // Match backend key
          method: result.value.paymentMethod, // Match backend key
        };

        job_payment(id, paymentData).then((res) => {
          getDetails(selectedParam.id);
          setIsActive(false);
        });
      } else {
        setIsActive(false);
      }
    });
  };





  return (
    <>
      <div className="tender bg-tender">
        {/* <Loader open={isActive} /> */}
        <BreadCrumbsComp section="downloaded_candidates_details" />
        <div className="container p-50">
          <div className="res-page-cont-details">
            <div className="res-det-con " ref={pageRef}>
              <div className="row tender-head ">
                <h4>
                  <b> Downloaded Jobseeker Details </b>
                </h4>

                <div>
                  <div className="container-ul">
                    <hr className="top hr" />
                    <hr className="bottom hr" />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between profile-box  mb-3">
                <div
                  className="col-6 text-success "
                // style={{ borderRight: "1px solid #0a223d" }}
                >
                  <div
                    className="w-100 d-flex flex-column "
                    style={{ borderRight: "1px solid black" }}
                  >
                    {data?.downloaded_profile_status >= 0 && (
                      <div>✅ Downloaded</div>
                    )}
                    <div className="ml-3">
                      <div>|</div>
                    </div>
                    {data?.downloaded_profile_status > 0 ? (
                      <div>✅ Interview Scheduled</div>
                    ) : (
                      <div>❌ Interview Scheduled</div>
                    )}
                    <div className="ml-3">
                      <div>|</div>
                    </div>

                    {data?.downloaded_profile_status > 1 ? (
                      <div>✅ Offer Letter Issued</div>
                    ) : (
                      <div>❌ Offer Letter Issued</div>
                    )}
                    <div className="ml-3">
                      <div>|</div>
                    </div>
                    {data?.downloaded_profile_status > 2 ? (
                      <div>✅ Candidate Joined</div>
                    ) : (
                      <div>❌ Candidate Joined</div>
                    )}
                    <div className="ml-3">
                      <div>|</div>
                    </div>
                    {data?.downloaded_profile_status > 3 ? (
                      <div>✅ Payment Confirmed</div>
                    ) : (
                      <div>❌ Payment Confirmed</div>
                    )}
                  </div>
                </div>
                <div className="col-6 d-flex flex-column align-items-center  justify-content-evenly">
                  <div className="col-11  pt-3">
                    {data?.downloaded_profile_status == 0 ? (
                      "If you are scheduling an interview with this candidate, please click on the below button."
                    ) : data?.downloaded_profile_status == 1 ? (
                      "If you have issued the offer letter, please confirm by clicking the below button."
                    ) : data?.downloaded_profile_status == 2 ? (
                      "If you want to confirm if the candidate has joined, please click the below button."
                    ) : data?.downloaded_profile_status == 3 ? (
                      "If you want to confirm that the payment has been done, please click the below button."
                    ) : data?.downloaded_profile_status == 4 ? (
                      <div className="text-success text-center pt-4">
                        <b style={{ fontSize: "24px" }}>Payment Completed</b>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="d-flex align-items-start  justify-content-end pb-3 ">
                    {data?.downloaded_profile_status == 0 && (
                      <button
                        type="button"
                        className="btn btn-approve btn-success m"
                        onClick={(e) =>
                          handleInterview(data?.downloaded_profile_id)
                        }
                      >
                        Schedule Interview
                      </button>
                    )}
                    {data?.downloaded_profile_status == 1 && (
                      <button
                        type="button"
                        className="btn btn-approve btn-success m"
                        onClick={(e) =>
                          handleConfirm(data?.downloaded_profile_id)
                        }
                      >
                        Issue Offer Letter
                      </button>
                    )}
                    {data?.downloaded_profile_status == 2 && (
                      <button
                        type="button"
                        className="btn btn-approve btn-success m"
                        onClick={(e) =>
                          handleJoined(data?.downloaded_profile_id)
                        }
                      >
                        Confirm Joining
                      </button>
                    )}
                    {data?.downloaded_profile_status == 3 && (
                      <button
                        type="button"
                        className="btn btn-approve btn-success m"
                        onClick={(e) =>
                          handlePayment(data?.downloaded_profile_id, data?.downloaded_profile_status)
                        }
                      >
                        Payment Completion
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-xs-12 ">
                  <div className="profile-box ">
                    <div className="res-bannerContainer d-flex ">
                      <div className="res-ban-image">
                        {/* <img
                        src="/resources/img/userdashbanner.png"
                        className="w-100 res-ban-img"
                        alt=""
                      /> */}
                      </div>
                      <div className="resbandata py-4 mt-2">
                        <div className="d-flex justify-content-center w-100">
                          {!data?.candidate_details?.photo ? (
                            <div className="res-name">
                              {data?.candidate_details?.name?.charAt(0)}
                            </div>
                          ) : (
                            <div>
                              <img
                                src={`${data?.candidate_details?.photo}`}
                                className="res-profile-img"
                                alt=""
                              />
                            </div>
                          )}
                          <div className="profile-sres-con ">
                            <div className="res-person-name d-flex align-items-center h-100">
                              {/* {data?.candidate_details?.prefix
                                ? data?.candidate_details?.prefix + "."
                                : "Mr."}{" "} */}
                              {data?.candidate_details?.name ?? ""}
                            </div>
                            {/* <div className="qualification-container">
                              <div className="res-exp mb-1">
                                Qualification :
                              </div>
                              <div className="exp-values ">
                                {data?.candidate_details?.education
                                  ? data?.candidate_details?.education[0]
                                      ?.edu_type_sub_one
                                  : ""}
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="res-about-sec">
                      <div className="res-abo-head">About</div>
                      <hr />
                      <div className="res-about-cont">
                        <div className="row">
                          <div className="col-4">Name</div>
                          <div className="col-1">:</div>
                          <div className="col-7 font-bold">
                            {" "}
                            <b>
                              {/* {data?.candidate_details?.prefix
                                ? data?.candidate_details?.prefix + "."
                                : "Mr."}{" "} */}
                              {data?.candidate_details?.name ?? ""}{" "}
                            </b>
                          </div>
                          <div className="col-4">Native of Kerala</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {" "}
                            {data?.candidate_details?.native_kerala == 1
                              ? "Yes"
                              : "No" ?? ""}
                          </div>
                          {data?.candidate_details?.native_kerala == 0 && (
                            <>
                              <div className="col-4">
                                Non Resident Keralite?
                              </div>
                              <div className="col-1">:</div>
                              <div className="col-7"> Yes</div>{" "}
                            </>
                          )}
                          {data?.candidate_details?.native_kerala == 0 && (
                            <>
                              <div className="col-4 ">
                                Proof of Native Address
                              </div>
                              <div className="col-1 ">:</div>
                              <div className="col-7">
                                {data?.candidate_details?.native_address_type}
                              </div>

                              <div className="col-4 ">
                                Uploaded Proof of Native Address
                              </div>
                              <div className="col-1">:</div>
                              <div className="col-7">
                                <div
                                  className="text-primary"
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    handleDownloadIdCert(
                                      data?.fcandidate_details?.non_keralite_file
                                    )
                                  }
                                >
                                  Download File
                                </div>
                              </div>
                            </>
                          )}
                          <div className="col-4">Identity Proof Type</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {data?.candidate_details?.identity_type}
                          </div>

                          <div className="col-4">Identity Number</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {data?.candidate_details?.identity_no}
                          </div>
                          {/* 
                        <div className="col-4">
                          Identity Number
                        </div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.identity_no}
                        </div> */}

                          <div className="col-4">ID proof with local address</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            <div
                              className="text-primary"
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                handleDownloadIdCert(
                                  data?.candidate_details?.identity_file
                                )
                              }
                            >
                              Download File
                            </div>
                          </div>

                          <div className="col-4 ">Fresher</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {" "}
                            {data?.candidate_details?.fresher ?? ""}{" "}
                          </div>
                          <div className="col-4">Date of Birth</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {data?.candidate_details?.dob}
                          </div>

                          <div className="col-4">Gender</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {data?.candidate_details?.gender}
                          </div>

                          <div className="col-4">Address</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {data?.candidate_details?.address}
                          </div>

                          <div className="col-4">Email Id</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {data?.candidate_details?.email}
                          </div>
                          <div className="col-4">Phone Number</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {data?.candidate_details?.contact}
                          </div>

                          <div className="col-4">Alternate Email</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {data?.candidate_details?.alt_email}
                          </div>

                          <div className="col-4">Alternate Phone</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {data?.candidate_details?.alt_code ? "+" : ""}
                            {data?.candidate_details?.alt_code}{" "}
                            {data?.candidate_details?.alt_contact}
                          </div>

                          <div className="col-4">Pin Code</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {data?.candidate_details?.pin_code}
                          </div>

                          <div className="col-4">Marital Status</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {data?.candidate_details?.marital_status}
                          </div>

                          <div className="col-4">Father / Spouse Name</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {data?.candidate_details?.parent_name}
                          </div>

                          <div className="col-4">Category</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {data?.candidate_details?.caste}
                          </div>

                          <div className="col-4">Differently Abled</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {data?.candidate_details?.handicapped}
                          </div>

                          {/* <div className="col-4">Blood Group</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {data?.candidate_details?.blood_group}
                          </div> */}

                          <div className="col-4">Blood Group</div>
                          <div className="col-1">:</div>
                          <div className="col-7">
                            {data?.candidate_details?.blood_group ==
                              "Other" ? (
                              <span className="">
                                {data?.candidate_details?.other_blood_group}
                              </span>
                            ) : (
                              data?.candidate_details?.blood_group
                            )}
                          </div>

                          <div className="col-4 ">Curriculum Vitae</div>
                          <div className="col-1 ">:</div>
                          <div className="col-7">
                            <div
                              className="text-primary"
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                handleDownloadIdCert(
                                  data?.candidate_details?.cv_upload
                                )
                              }
                            >
                              Download File
                            </div>
                          </div>

                          {/* <div className="col-4">Highest Qualification</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.qualification_type}
                        </div>

                        <div className="col-4">Qualification</div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.qualification}
                        </div> */}

                          {/* <div className="col-4">
                          Area of Interest
                        </div>
                        <div className="col-1">:</div>
                        <div className="col-7">
                          {data?.candidate_details?.area_of_interest && (
                            <>
                              {data?.candidate_details?.area_of_interest?.map((item, index) => (
                                <>
                                  {index !== 0 && <>&nbsp;|&nbsp;</>}
                                  {item.expertise_name}
                                </>
                              ))}
                            </>
                          )}
                        </div> */}

                          <div className="row-padding">
                            <div className="col-12 max-bold">
                              Educational Qualification
                            </div>

                            <div className="col-12  overflow-auto">
                              {" "}
                              <CustomTable
                                className="areatable"
                                {...props}
                                columns={[
                                  {
                                    title: "Qualification Type",
                                    field: "edu_type_parent",
                                  },
                                  {
                                    title: "Qualification",
                                    field: "edu_type_sub_one",
                                  },
                                  {
                                    title: "Stream",
                                    field: "edu_type_sub_two",
                                    render: (row) => (
                                      <>
                                        {row.edu_type_others_text
                                          ? row.edu_type_others_text
                                          : row?.edu_type_sub_two}{" "}
                                      </>
                                    ),
                                  },

                                  {
                                    title: " Board/University",
                                    field: "university",
                                  },
                                  { title: "City/State", field: "location" },
                                  { title: "Year of Passing", field: "year" },
                                  {
                                    title: "Attainment Type",
                                    field: "edu_qualification_type",
                                  },
                                  {
                                    title: "Attainment",
                                    field: "edu_qualification_text",
                                  },
                                  {
                                    title: "Equivalent Percentage",
                                    field: "percentage",
                                  },
                                  {
                                    title: "Certificate",
                                    field: "edu_certificate",
                                    type: "fdownload",
                                  },
                                ]}
                                data={data?.candidate_details?.education ?? []}
                              />
                            </div>
                          </div>
                          {data?.employment?.length !== 0 &&
                            <div className="row-padding">
                              <div className="col-12  max-bold">
                                Experience Details
                              </div>
                              <hr />
                              {data &&
                                data?.candidate_details?.employment &&
                                data?.candidate_details?.employment?.length !==
                                0 && (
                                  <>
                                    {data?.candidate_details?.employment?.map(
                                      (item, index) => (
                                        <div className="white">
                                          <div className="d-flex">
                                            <div className="col-4">Employer </div>
                                            <div className="col-1">:</div>
                                            <div className="col-7">
                                              {item.employer}
                                            </div>
                                          </div>
                                          <div className="d-flex">
                                            <div className="col-4">
                                              Contact No :{" "}
                                            </div>
                                            <div className="col-1">:</div>
                                            <div className="col-7">{`${item.contact}`}</div>
                                          </div>
                                          <div className="d-flex">
                                            <div className="col-4">Email Id</div>
                                            <div className="col-1">:</div>
                                            <div className="col-7">
                                              {item.email}
                                            </div>
                                          </div>

                                          <div className="d-flex">
                                            <div className="col-4">Designation</div>

                                            <div className="col-1">:</div>
                                            <div className="col-7">
                                              {item.position}
                                            </div>
                                          </div>
                                          <div className="d-flex">
                                            <div className="col-4">
                                              Currently Working
                                            </div>

                                            <div className="col-1">:</div>
                                            <div className="col-7">
                                              {item.working == "Yes"
                                                ? "Yes"
                                                : "No"}
                                            </div>
                                          </div>
                                          <div className="d-flex">
                                            <div className="col-4">
                                              {item.working == "Yes"
                                                ? "From"
                                                : "Period"}
                                            </div>
                                            <div className="col-1">:</div>
                                            <div className="col-7">
                                              {item.from}
                                              {item.working == "Yes" ? (
                                                <> (till now)</>
                                              ) : (
                                                <> to {item?.to}</>
                                              )}
                                            </div>
                                          </div>
                                          {item?.working == "No" && (
                                            <div className="d-flex">
                                              <div className="col-4">
                                                Reason for Leaving
                                              </div>
                                              <div className="col-1">:</div>{" "}
                                              <div className="col-7">
                                                {item.reason}
                                              </div>
                                            </div>
                                          )}
                                          <div className="d-flex">
                                            <div className="col-4">Address</div>
                                            <div className="col-1">:</div>
                                            <div className="col-7">
                                              {item.address}
                                            </div>
                                          </div>
                                          <div className="d-flex">
                                            <div className="col-4">
                                              {item.working == "Yes"
                                                ? "Appointment Letter"
                                                : "Experience Certificate"}
                                            </div>
                                            <div className="col-1">:</div>
                                            <div className="col-7">
                                              <div
                                                className="text-primary"
                                                style={{ cursor: "pointer" }}
                                                onClick={(e) =>
                                                  handleDownloadIdCert(
                                                    item.exp_certificate
                                                  )
                                                }
                                              >
                                                Download File
                                              </div>
                                            </div>
                                          </div>
                                          <hr />
                                        </div>
                                      )
                                    )}
                                  </>
                                )}
                            </div>}
                            {(data.candidate_details?.reference_contact1 || data.candidate_details?.reference_email1) &&
                          <div className="">
                            <div className="col-12  max-bold">Reference 1</div>
                            <hr />

                            <>
                              <div className="white">
                                <div className="d-flex">
                                  <div className="col-4">Name </div>
                                  <div className="col-1">:</div>
                                  <div className="col-7">
                                    {data.candidate_details?.reference_name1}
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div className="col-4">Designation </div>
                                  <div className="col-1">:</div>
                                  <div className="col-7">
                                    {
                                      data.candidate_details
                                        ?.reference_designation1
                                    }
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div className="col-4">Contact No</div>
                                  <div className="col-1">:</div>
                                  <div className="col-7">
                                    +{data.candidate_details?.reference_contact1 ? `${data.candidate_details?.reference_contact1.split("-")[0]} ${data.candidate_details?.reference_contact1.split("-")[1]}` : ""}
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div className="col-4">Email Id</div>
                                  <div className="col-1">:</div>
                                  <div className="col-7">
                                    {data.candidate_details?.reference_email1}
                                  </div>
                                </div>

                                <hr />
                              </div>
                            </>
                          </div> }
                          {(data.candidate_details?.reference_contact2 || data.candidate_details?.reference_email2) &&
                          <div className="">
                            <div className="col-12  max-bold">Reference 2</div>
                            <hr />

                            <>
                              <div className="white">
                                <div className="d-flex">
                                  <div className="col-4">Name </div>
                                  <div className="col-1">:</div>
                                  <div className="col-7">
                                    {data.candidate_details?.reference_name2}
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div className="col-4">Designation </div>
                                  <div className="col-1">:</div>
                                  <div className="col-7">
                                    {
                                      data.candidate_details
                                        ?.reference_designation2
                                    }
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div className="col-4">Contact No</div>
                                  <div className="col-1">:</div>
                                  <div className="col-7">
                                    +{data.candidate_details?.reference_contact2 ? `${data.candidate_details?.reference_contact2.split("-")[0]} ${data.candidate_details?.reference_contact2.split("-")[1]}` : ""}
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div className="col-4">Email Id</div>
                                  <div className="col-1">:</div>
                                  <div className="col-7">
                                    {data.candidate_details?.reference_email2}
                                  </div>
                                </div>

                                <hr />
                              </div>
                            </>
                          </div>}
                          <div className="d-flex">
                            <div className="col-4">Area of Interest</div>
                            <div className="col-1 ">:</div>
                            <div className="col-7">
                              {data?.candidate_details?.interest_type}
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="col-4">Preferences</div>
                            <div className="col-1 ">:</div>
                            <div className="col-7">
                              {data &&
                                data?.candidate_details?.interest &&
                                data?.candidate_details?.interest.length !==
                                0 && (
                                  <>
                                    {JSON.parse(
                                      data?.candidate_details?.interest
                                    )?.map((item, index) => (
                                      <div key={index}>{item}</div>
                                    ))}
                                    {data?.candidate_details
                                      ?.any_other_domain && (
                                        <div>
                                          {
                                            data?.candidate_details
                                              ?.any_other_domain
                                          }
                                        </div>
                                      )}
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex mt-3 justify-content-center">
                <button
                  type="button"
                  className="btn btn-approve btn-success m"
                  onClick={(e) => handleBack()}
                >
                  Go Back
                </button>
                <button
                  type="button"
                  className="btn btn-approve btn-success m"
                  onClick={(e) => handleDownload()}
                >
                  Download
                </button>

                {/* {data?.downloaded_profile_status == 1 && (
                <button
                  type="button"
                  className="btn btn-approve btn-success m"
                  // onClick={(e) => handleJob()}
                  disabled
                >
                  Contact admin to make payment
                </button>
              )} */}
              </div>
              {/* pdf download */}
            </div>
          </div>
        </div>
      </div>

      <PaymentDetailsModal
        setDetailsOpen={setDetailsOpen}
        detailsOpen={detailsOpen}
      />
    </>
  );
}
