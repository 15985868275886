import React from "react";

import "./resourse.css";

export default function ResourseTileCard(props) {
  const expertiseIn = (data) => {
    let expin = "";
    let dataArray = [];
    data.forEach((item, i) => {
      if (i > 0) {
        dataArray.push(" | ");
      }
      dataArray.push(item.expertise_name);
    });
    console.log(dataArray);
    expin = String(dataArray) || "";
    return expin;
  };
console.log(props)
  return (
    <div className="card " style={{ width: "19rem", margin: "10px" }}>
      <div className="jobSeek-image">
      <img src={props?.data?.photo ?? props?.data?.candidate_details?.photo} alt="" className="img-fluid rounded-top cover-image" />
      </div>

      <div class="card-body">
     
          
            {(props.candidate && props.status) && props.status === 4 ? (
              <div className="status-ribbon ">
              <div className="text-success bolded_text">Payment Done</div> </div>
            ) : props?.status === 3 ? (
              <div className="status-ribbon ">
              <div className="text-info bolded_text">Joined Successfully</div> </div>
            ) : props?.status === 2 ? (
              <div className="status-ribbon ">
              <div className="text-success bolded_text">
                Offer Letter Issued
              </div> </div>
            ) : props?.status === 1 ? (
              <div className="status-ribbon ">
              <div className="text-info bolded_text">Interview Scheduled</div> </div>
            ) : props?.status === 0 ? (
              <div className="status-ribbon ">
              <div className="text-info bolded_text">Downloaded</div> </div>
            ) : (
              ""
            )}
          
         
          <h5 class="card-title text-truncate text-capitalize"><b>{props.title}</b></h5>
          {/* <div className="res-email text-start p-0">Email : {props.email}</div> */}
          <div className=" d-flex my-1 border-top">
             
             <div className="text-truncate pt-3"><b>Email :</b><span className="" style={{paddingLeft : "7px"}}> {" "}{props.email}</span></div>
             {/* <p className="expertize-decs "></p> */}
            
           </div>
          {props.candidate && (
            <div className=" d-flex my-1 ">
             
              <div className="text-truncate"><b>Qualification :</b><span className="" style={{paddingLeft : "7px"}}> {" "}{props?.qualification}</span></div>
              {/* <p className="expertize-decs "></p> */}
             
            </div>
          )}
          {/* {props.candidate && (
          <>
            <div className="res-exp-heading">Interests</div>
            <p className="expertize-decs">
             {props?.qualification}
            </p>
          </>
        )} */}

          <div className="d-flex justify-content-center mt-4">
            <button
              className="primary-btn"
              onClick={(e) => {
                props.history.push(props.path);
              }}
            >
              Visit Profile
            </button>
          </div>
          <div></div>
       
      </div>
    </div>
  );
}
