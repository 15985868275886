import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CustomTable from "../../../components/table/table";
import "./view.css";
import {
  get_candidates_details,
  get_manpower_details,
  update_admin_candidates_status,
  update_candidates_status,
  update_jobseeker_status,
  update_manpower_status,
} from "../admin.api";
import Snackbar from "../../../components/snackbar/snackbar";
import Loader from "../../../components/loader/loader";
import BreadCrumbsComp from "../../../components/navigator/breadcrumbs";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import moment from "moment";
import { Toast } from "react-bootstrap";
export default function View(props) {
  const [data, setData] = useState([]);

  const [isActive, setIsActive] = useState(true);
  const [snackMessage, setSnackMessage] = useState("");
  const [isSnackError, setIsSnackError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [revert, setRevert] = useState([]);
  const [checkFile, setCheckFile] = useState([]);
  const [url, setUrl] = useState("");
  const servInstance = process.env.REACT_APP_BASE_URL;
  let result = servInstance.substring(0, servInstance?.length - 4);

  const handleEdit = () => {};
  const handleDownload = (item) => {
    let path = `${result}${item.file_upload_certificate}`;
    window.open(path, "_blank");
  };
  const handlefullDownload = (item) => {
    let path = `${item.file_upload_certificate}`;
    window.open(path, "_blank");
  };
  let adminType = Number(jwtDecode(props.state.payload).user_type);
  const selectedParam = useParams();

  const getDetails = (id) => {
    get_candidates_details(id).then((res) => {
      let tdata = res.data;
      setData(tdata);
      setIsActive(false);
    });
  };
  useEffect(() => {
    // localStorage.setItem(selectedParam.id , null)
    getDetails(selectedParam.id);
  }, []);
  const handleDownloadBiodata = () => {
    let path = `${result}${data.biodata}`;
    window.open(path, "_blank");
  };
  const handleDownloadExpCert = (data) => {
    let path = `${result}${data}`;
    window.open(path, "_blank");
  };
  const handleDownloadIdCert = (data) => {
    let path = `${data}`;
    window.open(path, "_blank");
  };

  const saMessage = (status, type) => {
    
    let CheckMessage =
      checkFile.filter((item) => item.trim() !== "").join(", ") ?? "";
    if (adminType === 1 && CheckMessage !== "") {
      setSnackMessage(`Please verify these documents - ${CheckMessage}`);
      setIsSnackError(true);
      setSnackOpen(true);
      setIsActive(false);
      
      return false;
    }
    Swal.fire({
      inputLabel: "Message",
      input: "textarea",
      showCancelButton: false,
      confirmButtonColor: "#0A223D",
      confirmButtonText: "Submit",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      inputValidator: (value) => {
        if (!value) {
          return "Please describe the reason";
        }
      },
      showCancelButton: true,
    }).then((message) => {
      if (message.isConfirmed === true) {
        let mandetails = data?.id;
        // const revertmessages = revert.map((item) => item).join(", ");
        const revertmessages = revert
          .filter((item) => item.trim() !== "")
          .join(", ");

       
        console.log(message.value);
        let mdata = {};

        if (adminType === 1) {
          if (status === 1) {
            mdata = {
              status: status,
              // em_id: mandetails,
              message: message.value,
            };
          } else {
            mdata = {
              status: status,
              // em_id: mandetails,
              user_message: `${revertmessages},${message.value}`,
            };
          }
        } else {
          mdata = {
            status: status,
            // em_id: mandetails,
            superadmin_message: message.value,
          };
        }
        // let data = { status: status, super_admin_message: message.value };
        setIsActive(true);

        if (type === "admin") {
          update_admin_candidates_status(mdata, selectedParam.id)
            .then((res) => {
              setSnackMessage(res.message);
              setIsSnackError(false);
              setSnackOpen(true);
              setIsActive(false);
            })
            .catch((error) => {
              setSnackMessage(error.message);
              setIsSnackError(true);
              setSnackOpen(true);
              setIsActive(false);
            });
        } else if (type === "sa") {
          update_candidates_status(mdata, selectedParam.id)
            .then((res) => {
              setSnackMessage(res.message);
              setIsSnackError(false);
              setSnackOpen(true);
              setIsActive(false);
            })
            .catch((error) => {
              setSnackMessage(error.message);
              setIsSnackError(true);
              setSnackOpen(true);
              setIsActive(false);
            });
        }
      }
    });
  };
  const handleAdminActions = (status) => {
    let mandetails = data?.id;
    let mdata = { status_id: status, em_id: mandetails };
    setIsActive(true);
    update_manpower_status(mdata)
      .then((res) => {
        setSnackMessage(res.message);
        setIsSnackError(false);
        setSnackOpen(true);
        setIsActive(false);
      })
      .catch((error) => {
        setSnackMessage(error.message);
        setIsSnackError(true);
        setSnackOpen(true);
        setIsActive(false);
      });
  };
  const snackbarClose = (flag) => {
    setSnackMessage("");
    setIsSnackError(false);
    setSnackOpen(false);
    if (!flag) {
      props.history.push("/candidates-list");
    }
  };
  // const expArea = JSON.parse(data?.expertise_area)
  // console.log(expArea)
  const handleAcceptFile = (a, b) => {
    let fData = formData;

    if (a === "edu_certificate_status" || a === "exp_certificate_status") {
      fData = {
        ...fData,
        [a]: true,
        id: b,
      };
      setFormData(fData);
    } else {
      fData = {
        ...fData,
        [a]: true,
      };
      setFormData(fData);
    }
    update_jobseeker_status(fData, selectedParam.id).then((res) => {
      getDetails(selectedParam.id);
      setFormData({});
    });
  };
  const handleRejectFile = (a, b) => {
    let fData = formData;
    const rejectMessageKey = a.replace("_status", "_message");
    Swal.fire({
      inputLabel: "Reason for rejection",
      input: "textarea",
      showCancelButton: false,
      confirmButtonColor: "#0A223D",
      confirmButtonText: "Submit",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      inputValidator: (value) => {
        if (!value) {
          return "Please describe the reason";
        }
      },
      showCancelButton: true,
    }).then((message) => {
      if (message.isConfirmed === true ) {
        if (a === "edu_certificate_status" || a === "exp_certificate_status") {
          fData = {
            ...fData,
            [a]: false,
            id: b,
            [rejectMessageKey]: message?.value,
          };
          setFormData(fData);
        } else {
          fData = {
            ...fData,
            [a]: false,
            [rejectMessageKey]: message?.value,
          };
          setFormData(fData);
        }

        update_jobseeker_status(fData, selectedParam.id).then((res) => {
          getDetails(selectedParam.id);
          setFormData({});
        });
      }
    });
  };
  useEffect(() => {
    let msgData = [
      data?.identity_file_message ?? "",
      data?.non_keralite_file_message ?? "",
      data?.handicapped_file_message ?? "",
      data?.photo_message ?? "",
      data?.cv_upload_message ?? "",
    ];
    if (data?.education) {
      data?.education.map((item, i) =>
        msgData.push(item.edu_certificate_message ?? "")
      );
    }
    if (data?.employment) {
      data?.employment.map((item, i) =>
        msgData.push(item.exp_certificate_message ?? "")
      );
    }

    setRevert(msgData);
  }, [data, data?.education, data?.employment]);
  useEffect(() => {
    let msgData = [
      data?.identity_file && !data?.identity_file_status
        ? "ID proof with local address"
        : "",
      data?.non_keralite_file && !data?.non_keralite_file_status
        ? "Proof of Native Address File"
        : "",
      data?.handicapped_file && !data?.handicapped_file_status
        ? "Disability Certificate"
        : "",
      data?.photo && !data?.photo_status ? "Photo" : "",
      data?.cv_upload && !data?.cv_upload_status ? "CV" : "",
    ];
    if (data?.education) {
      data?.education.map((item, i) =>
        msgData.push(
          item.edu_certificate && !item.edu_certificate_status
            ? "Certificates of Educational Qualifications"
            : ""
        )
      );
    }
    if (data?.employment) {
      data?.employment.map((item, i) =>
        msgData.push(
          item.exp_certificate && !item.exp_certificate_status
            ? "Experience Certificate / Appointment Letter"
            : ""
        )
      );
    }

    setCheckFile(msgData);
  }, [data, data?.education, data?.employment]);
  console.log(revert, data, data?.education);

  return (
    <div>
      <Snackbar
        message={snackMessage}
        error={isSnackError}
        open={snackOpen}
        close={snackbarClose}
      />

      <Loader open={isActive} />
      <div className="view-container">
        <span className="d-flex justify-content-between">
          <h6 className="mb-0 pt-2">
            <b>Candidate Details</b>
          </h6>
          {/*      
        <button type="button" className="btn btn-primary">
          Back to List
        </button> */}
        </span>
        <hr />
        {/* <button
          type="button"
          className="btn btn-secondary btn-sm  mb-3"
          onClick={(e) => {
            props.history.push("/event-list/");
          }}
        >
          Back
        </button> */}
        <div className="data">
          <div className="row">
            <div className="col-3">Are you a native of Kerala?</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data?.native_kerala == 1 ? "Yes" : "No" ?? ""}
            </div>
          </div>
          {data?.native_kerala == 0 && (
            <div className="row">
              <div className="col-3">Are you a Non Resident Keralite?</div>
              <div className="col-1">:</div>
              <div className="col-7">Yes</div>
            </div>
          )}
          {data?.native_address_type && (
            <div className="row">
              <div className="col-3">Proof of Native Address</div>
              <div className="col-1">:</div>
              <div className="col-7">{data?.native_address_type}</div>
            </div>
          )}
          {data?.native_address_type && (
            <div className="row">
              <div className="col-3"> Uploaded Proof of Native Address</div>
              <div className="col-1">:</div>
              <div className="col-5">
                <button
                  className="btn btn-approve btn-success mx-0"
                  onClick={(e) => handleDownloadIdCert(data?.non_keralite_file)}
                >
                  Download File
                </button>
              </div>
              {!data?.non_keralite_file_status &&
              !formData?.non_keralite_file_status &&
              adminType !== 2 ? (
                <div className="col-3 d-flex justify-content-end">
                  <button
                    className="btn btn-approve-check btn-success"
                    onClick={(e) =>
                      handleAcceptFile("non_keralite_file_status")
                    }
                  >
                    🗸
                  </button>
                  <button
                    className="btn btn-approve-check-cross  btn-outline-danger"
                    onClick={(e) =>
                      handleRejectFile("non_keralite_file_status")
                    }
                  >
                    ❌
                  </button>
                </div>
              ) : (
                <div className="col-3 d-flex justify-content-end align-items-center">
                  {formData?.non_keralite_file_status == true ||
                  data?.non_keralite_file_status == true ? (
                    <span className="text-success">Accepted</span>
                  ) : formData?.non_keralite_file_status == false ||
                    data?.non_keralite_file_status == false ? (
                    <span className="text-danger">Rejected</span>
                  ) : (
                    <span className="text-primary">Pending</span>
                  )}
                </div>
              )}
              {data?.non_keralite_file_status == false && (
                <div className="d-flex pt-2">
                  <div className="col-3">Reason for rejection</div>
                  <div className="col-1 pad-sub">:</div>
                  <div className="col-7 text-danger pad-sub">
                    {data?.non_keralite_file_message ??
                      "ighifuiufgiwuefgiuweifuwiugfi"}
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="row">
            <div className="col-3">Type of Identity Proof</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.identity_type}</div>
          </div>
          <div className="row">
            <div className="col-3">Identity Number</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.identity_no}</div>
          </div>
          <div className="row">
            <div className="col-3"> Identity Proof</div>
            <div className="col-1">:</div>
            <div className="col-5">
              <button
                className="btn btn-approve btn-success mx-0"
                onClick={(e) => handleDownloadIdCert(data?.identity_file)}
              >
                Download File
              </button>
              {/* {data?.identity_file_status == false && (
                <div>Reason for Rejection : {data?.identity_file_message}</div>
              )} */}
            </div>
            {!data?.identity_file_status &&
            !formData?.identity_file_status &&
            adminType !== 2 ? (
              <div className="col-3 d-flex justify-content-end">
                <button
                  className="btn btn-approve-check btn-success "
                  onClick={(e) => handleAcceptFile("identity_file_status")}
                >
                  🗸
                </button>
                <button
                  className="btn btn-approve-check-cross  btn-outline-danger"
                  onClick={(e) => handleRejectFile("identity_file_status")}
                >
                  ❌
                </button>
              </div>
            ) : (
              <div className="col-3 d-flex justify-content-end align-items-center">
                {formData?.identity_file_status == true ||
                data?.identity_file_status == true ? (
                  <span className="text-success">Accepted</span>
                ) : formData?.identity_file_status == false ||
                  data?.identity_file_status == false ? (
                  <span className="text-danger">Rejected</span>
                ) : (
                  <span className="text-primary">Pending</span>
                )}
              </div>
            )}
            {data?.identity_file_status == false && (
              <div className="d-flex pt-2">
                <div className="col-3">Reason for rejection</div>
                <div className="col-1 pad-sub">:</div>
                <div className="col-7 text-danger pad-sub">
                  {data?.identity_file_message ??
                    "ighifuiufgiwuefgiuweifuwiugfi"}
                </div>
              </div>
            )}
          </div>

         
          <div className="row">
            <div className="col-3">Fresher?</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.fresher}</div>
          </div>
          <div className="row">
            <div className="col-3">Name (as per Aadhaar)</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {/* {data?.prefix ? data?.prefix + "." : "Mr."} {data?.name ?? ""}{" "} */}
              {data?.name ?? ""}
            </div>
          </div>

          <div className="row">
            <div className="col-3">Date of Birth</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data?.dob
                ? moment(data?.dob, "YYYY-MM-DD").format("DD-MM-YYYY")
                : ""}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Gender</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.gender ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Address</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.address}</div>
          </div>
          <div className="row">
            <div className="col-3">Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.email}</div>
          </div>
          <div className="row">
            <div className="col-3">Phone Number</div>
            <div className="col-1">:</div>
            <div className="col-7">+91 {data?.contact}</div>
          </div>
          <div className="row">
            <div className="col-3">Alternate Email</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.alt_email}</div>
          </div>
          <div className="row">
            <div className="col-3">Alternate Phone</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data?.alt_code ? "+" : ""}
              {data?.alt_code} {data?.alt_contact}
            </div>
          </div>
          <div className="row">
            <div className="col-3">Pin Code</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.pin_code}</div>
          </div>
          <div className="row">
            <div className="col-3">Marital Status</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.marital_status ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Father / Spouse Name</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.parent_name ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Category</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.caste ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Are you Differently Abled?</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.handicapped ?? ""}</div>
          </div>
          {data?.handicapped == "Yes" && (
            <div className="row">
              <div className="col-3"> Disability Certificate</div>
              <div className="col-1">:</div>
              <div className="col-5">
                <button
                  className="btn btn-approve btn-success mx-0"
                  onClick={(e) => handleDownloadIdCert(data?.handicapped_file)}
                >
                  Download File
                </button>
              </div>
              {!data?.handicapped_file_status &&
              !formData?.handicapped_file_status &&
              adminType !== 2 ? (
                <div className="col-3 d-flex justify-content-end">
                  <button
                    className="btn btn-approve-check btn-success"
                    onClick={(e) => handleAcceptFile("handicapped_file_status")}
                  >
                    🗸
                  </button>
                  <button
                    className="btn btn-approve-check-cross  btn-outline-danger"
                    onClick={(e) => handleRejectFile("handicapped_file_status")}
                  >
                    ❌
                  </button>
                </div>
              ) : (
                <div className="col-3 d-flex justify-content-end align-items-center">
                  {formData?.handicapped_file_status == true ||
                  data?.handicapped_file_status == true ? (
                    <span className="text-success">Accepted</span>
                  ) : formData?.handicapped_file_status == false ||
                    data?.handicapped_file_status == false ? (
                    <span className="text-danger">Rejected</span>
                  ) : (
                    <span className="text-primary">Pending</span>
                  )}
                </div>
              )}
              {data?.handicapped_file_status == false && (
                <div className="d-flex pt-2">
                  <div className="col-3">Reason for rejection</div>
                  <div className="col-1 pad-sub">:</div>
                  <div className="col-7 text-danger pad-sub">
                    {data?.handicapped_file_message ??
                      "ighifuiufgiwuefgiuweifuwiugfi"}
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="row">
            <div className="col-3">Blood Group</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data?.blood_group == "Other" ? (
                <span className="text-danger">{data?.other_blood_group}</span>
              ) : (
                data?.blood_group
              )}
            </div>
          </div>
          {/* {data?.blood_group == "Other" &&
          <div className="row">
          <div className="col-3">Specify Blood Group</div>
          <div className="col-1">:</div>
          <div className="col-7">{data?.other_blood_group}</div>
        </div>
          } */}

          <div className="row">
            <div className="col-3"> Photo</div>
            <div className="col-1">:</div>
            <div className="col-5">
              <button
                className="btn btn-approve btn-success mx-0"
                onClick={(e) => handleDownloadIdCert(data?.photo)}
              >
                Download File
              </button>
            </div>
            {!data?.photo_status &&
            !formData?.photo_status &&
            adminType !== 2 ? (
              <div className="col-3 d-flex justify-content-end">
                <button
                  className="btn btn-approve-check btn-success"
                  onClick={(e) => handleAcceptFile("photo_status")}
                >
                  🗸
                </button>
                <button
                  className="btn btn-approve-check-cross  btn-outline-danger"
                  onClick={(e) => handleRejectFile("photo_status")}
                >
                  ❌
                </button>
              </div>
            ) : (
              <div className="col-3 d-flex justify-content-end align-items-center">
                {formData?.photo_status == true ||
                data?.photo_status == true ? (
                  <span className="text-success">Accepted</span>
                ) : formData?.photo_status == false ||
                  data?.photo_status == false ? (
                  <span className="text-danger">Rejected</span>
                ) : (
                  <span className="text-primary">Pending</span>
                )}
              </div>
            )}
            {data?.photo_status == false && (
              <div className="d-flex pt-2">
                <div className="col-3">Reason for rejection</div>
                <div className="col-1 pad-sub">:</div>
                <div className="col-7 text-danger pad-sub">
                  {data?.photo_message ?? "ighifuiufgiwuefgiuweifuwiugfi"}
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-3"> CV File</div>
            <div className="col-1">:</div>
            <div className="col-5">
              <button
                className="btn btn-approve btn-success mx-0"
                onClick={(e) => handleDownloadIdCert(data?.cv_upload)}
              >
                Download File
              </button>
            </div>
            {!data?.cv_upload_status &&
            !formData?.cv_upload_status &&
            adminType !== 2 ? (
              <div className="col-3 d-flex justify-content-end">
                <button
                  className="btn btn-approve-check btn-success"
                  onClick={(e) => handleAcceptFile("cv_upload_status")}
                >
                  🗸
                </button>
                <button
                  className="btn btn-approve-check-cross  btn-outline-danger"
                  onClick={(e) => handleRejectFile("cv_upload_status")}
                >
                  ❌
                </button>
              </div>
            ) : (
              <div className="col-3 d-flex justify-content-end align-items-center">
                {formData?.cv_upload_status == true ||
                data?.cv_upload_status == true ? (
                  <span className="text-success">Accepted</span>
                ) : formData?.cv_upload_status == false ||
                  data?.cv_upload_status == false ? (
                  <span className="text-danger">Rejected</span>
                ) : (
                  <span className="text-primary">Pending</span>
                )}
              </div>
            )}
            {data?.cv_upload_status == false && (
              <div className="d-flex pt-2">
                <div className="col-3">Reason for rejection</div>
                <div className="col-1 pad-sub">:</div>
                <div className="col-7 text-danger pad-sub">
                  {data?.cv_upload_message ?? "ighifuiufgiwuefgiuweifuwiugfi"}
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-3">Created At</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data?.created_at
                ? moment(data?.created_at).format("DD-MM-YYYY")
                : ""}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-3">Highest Qualification</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.qualification_type}</div>
          </div>
          <div className="row">
            <div className="col-3">Qualification</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.qualification}</div>
          </div> */}
          {/* <div className="row">
            <div className="col-3 pb-2">Educational Qualification</div>
            <div className="col-1">:</div>
            <div className="col-12 pb-2  mt-00">
              <CustomTable
                {...props}
                columns={[
                  {
                    title: "Qualification Type",
                    field: "edu_type_parent",
                  },
                  { title: "Qualification", field: "edu_type_sub_one" },
                  { title: "Stream", field: "edu_type_sub_two" },
                  {
                    title: "Stream Specification",
                    field: "edu_type_others_text",
                    type: "otherSpec",
                  },
                  { title: " Board/University", field: "university" },
                  { title: "Location", field: "location" },
                  { title: "Year of Passing", field: "year" },
                  // { title: "Grade", field: "grade" },
                  { title: "Attainment Type", field: "edu_qualification_type" },
                  { title: "Attainment", field: "edu_qualification_text" },
                  { title: "Equivalent Percentage", field: "percentage" },
                  {
                    title: "Certificate",
                    field: "edu_certificate",
                    type: "fdownload",
                  },

                  // {
                  //   title: "Certificate",
                  //   field: "file_upload_certificate",
                  //   type: "download",
                  // },
                ]}
                data={data?.education ?? []}
                fileactions
                handleAcceptFile={handleAcceptFile}
                handleRejectFile={handleRejectFile}
                handleDownload={handleDownload}
              />
            </div>
          </div> */}
          <div className="row">
            <div className="col-3">Educational Qualifications</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data && data?.education && data?.education?.length !== 0 && (
                <>
                  {data?.education?.map((item, index) => (
                    <>
                      <div className="white row mt-2">
                        <div className="col-6">
                          <b>Qualification Type</b>
                          <p>{item.edu_type_parent}</p>
                        </div>
                        <div className="col-6">
                          <b>Qualification</b>
                          <p>{`${item.edu_type_sub_one}`}</p>
                        </div>
                        <div className="col-6">
                          <b>Stream</b>
                          <p>{item.edu_type_sub_two}</p>
                        </div>
                        {item.edu_type_others_text &&
                        <div className="col-6">
                          <b>Stream Specification</b>
                          <p className="text-danger">
                            {item.edu_type_others_text}
                          </p>
                        </div>}
                        <div className="col-6">
                          <b>Board/University</b>
                          <p>{item?.university}</p>
                        </div>
                        <div className="col-6">
                          <b>City/State</b>
                          <p>{item?.location}</p>
                        </div>
                        <div className="col-6">
                          <b>Year of Passing</b>
                          <p>{item?.year}</p>
                        </div>
                        <div className="col-6">
                          <b>Attainment Type</b>
                          <p>{item?.edu_qualification_type}</p>
                        </div>
                        <div className="col-6">
                          <b>Attainment </b>
                          <p>{item?.edu_qualification_text}</p>
                        </div>
                        <div className="col-6">
                          <b>Equivalent Percentage </b>
                          <p>{item?.percentage}</p>
                        </div>

                        <div className="d-flex">
                          <div className="col-7 ">
                            <b>Certificate</b>
                            <p>
                              <button
                                className="btn btn-approve btn-success m-0 mt-2"
                                onClick={(e) =>
                                  handleDownloadIdCert(item.edu_certificate)
                                }
                              >
                                Download File
                              </button>
                            </p>
                          </div>
                          {!item?.edu_certificate_status  && adminType !== 2 ? (
                            <div className="col-4 d-flex justify-content-end align-items-center">
                              <div className="">
                                <button
                                  className="btn btn-approve-check btn-success"
                                  onClick={(e) =>
                                    handleAcceptFile(
                                      "edu_certificate_status",
                                      item.id
                                    )
                                  }
                                >
                                  🗸
                                </button>
                                <button
                                  className="btn btn-approve-check-cross  btn-outline-danger"
                                  onClick={(e) =>
                                    handleRejectFile(
                                      "edu_certificate_status",
                                      item.id
                                    )
                                  }
                                >
                                  ❌
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className="col-3 d-flex justify-content-end align-items-center">
                              {item.edu_certificate_status == true ? (
                                <span className="text-success">Accepted</span>
                              ) : item.edu_certificate_status == false ? (
                                <span className="text-danger">Rejected</span>
                              ) : (
                                <span className="text-primary">Pending</span>
                              )}
                            </div>
                          )}
                        </div>
                        {item?.edu_certificate_status == false && (
                          <div className="col-12">
                            <b>Reason for rejection</b>
                            <p className="text-danger">
                              {item.edu_certificate_message}
                            </p>
                          </div>
                        )}
                      </div>
                      {data?.employment.length > 1 &&
                        data.employment.length !== index + 1 && <hr />}
                    </>
                  ))}
                </>
              )}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-3">Alternate Phone</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data?.alternate_phone &&
                data?.country_code &&
                data?.country_code !== "" &&
                data?.country_code !== "null" &&
                data?.country_code !== "undefined" && (
                  <>
                    {data?.country_code ?? ""}{" "}
                    {data?.country_code && data?.country_code !== ""
                      ? "-"
                      : ""}{" "}
                  </>
                )}
              {data?.alternate_phone ?? ""}
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-3">Languages Known</div>
            <div className="col-1">:</div>
            <div className="col-7">
              Malayalam&nbsp;&nbsp;|&nbsp;&nbsp;English&nbsp;&nbsp;|&nbsp;&nbsp;Hindi
            </div>
          </div> */}

          {/* <div className="row">
            <div className="col-3">Area of Expertise:</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data &&
                data?.area_of_expertise &&
                data?.area_of_expertise.length !== 0 && (
                  <>
                    {data?.area_of_expertise?.map((item, index) => (
                      <div>{item.expertise_name}</div>
                    ))}
                  </>
                )}
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-3">Any other area of interest</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data &&
                data.area_of_interest &&
                data?.area_of_interest.length !== 0 && (
                  <>
                    {data?.area_of_interest?.map((item, index) => (
                      <span>
                        {index !== 0 && " | "}
                        {item.area_of_interest_name}
                      </span>
                    ))}
                  </>
                )}
            </div>
          </div> */}

          {/* <div className="row">
            <div className="col-3">Employment Details</div>
            <div className="col-1">:</div>
            <div className="col-7  mt-00">
              <CustomTable
                {...props}
                columns={[
                  { title: " Employer", field: "employer" },
                  { title: "Contact", field: "contact" },
                  { title: "Email", field: "email" },
                  { title: "Designation", field: "position" },
                  { title: "From", field: "from" },
                  { title: "To", field: "to" },
                  {
                    title: "Certificate",
                    field: "exp_certificate",
                    type: "download",
                  },
                ]}
                data={data?.employment ?? []}
                handleDownload={handlefullDownload}
              />
            </div>
          </div> */}
          {data?.fresher === "No" && (
            <div className="row">
              <div className="col-3">Employment Details</div>
              <div className="col-1">:</div>
              <div className="col-7">
                {data && data?.employment && data?.employment?.length !== 0 && (
                  <>
                    {data?.employment?.map((item, index) => (
                      <>
                        <div className="white row mt-2">
                          <div className="col-6">
                            <b>Employer</b>
                            <p>{item.employer}</p>
                          </div>
                          <div className="col-6">
                            <b>Contact No</b>
                            <p>{`${item.contact}`}</p>
                          </div>
                          <div className="col-6">
                            <b>Email Id</b>
                            <p>{item.email}</p>
                          </div>
                          <div className="col-6">
                            <b>Designation</b>
                            <p>{item.position}</p>
                          </div>
                          <div className="col-6">
                            <b>Currently Working</b>
                            <p>{item?.working == "Yes" ? "Yes" : "No"}</p>
                          </div>
                          <div className="col-6">
                            <b>{item?.working == "Yes" ? "From" : "Period"}</b>
                            <p>
                              {item.from}{" "}
                              {item?.working == "Yes" ? (
                                <>(till now)</>
                              ) : (
                                <> to {item?.to}</>
                              )}
                            </p>
                          </div>
                          {item?.working != "Yes" && (
                            <div className="col-6">
                              <b>Reason for Leaving</b>
                              <p>{item.reason}</p>
                            </div>
                          )}
                          <div className="col-6">
                            <b>Address</b>
                            <p>{item.address}</p>
                          </div>
                          <div className="d-flex">
                            <div className="col-7">
                              <b>
                                {item.working == "Yes"
                                  ? "Appointment Letter"
                                  : "Experience Certificate"}
                              </b>
                              <p>
                                <button
                                  className="btn btn-approve btn-success m-0 mt-2"
                                  onClick={(e) =>
                                    handleDownloadIdCert(item.exp_certificate)
                                  }
                                >
                                  Download File
                                </button>
                              </p>
                            </div>
                            {!item?.exp_certificate_status &&
                            adminType !== 2 ? (
                              <div className="col-4 d-flex justify-content-end align-items-center">
                                <div className="">
                                  <button
                                    className="btn btn-approve-check btn-success"
                                    onClick={(e) =>
                                      handleAcceptFile(
                                        "exp_certificate_status",
                                        item.id
                                      )
                                    }
                                  >
                                    🗸
                                  </button>
                                  <button
                                    className="btn btn-approve-check-cross  btn-outline-danger"
                                    onClick={(e) =>
                                      handleRejectFile(
                                        "exp_certificate_status",
                                        item.id
                                      )
                                    }
                                  >
                                    ❌
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <div className="col-3 d-flex justify-content-end align-items-center">
                                {item.exp_certificate_status == true ? (
                                  <span className="text-success">Accepted</span>
                                ) : item.exp_certificate_status == false ? (
                                  <span className="text-danger">Rejected</span>
                                ) : (
                                  <span className="text-primary">Pending</span>
                                )}
                              </div>
                            )}
                          </div>
                          {item?.exp_certificate_status == false && (
                            <div className="col-12">
                              <b>Reason for rejection</b>
                              <p className="text-danger">
                                {item.exp_certificate_message ?? "No reason provided"}
                              </p>
                            </div>
                          )}
                        </div>
                        {data?.employment.length > 1 &&
                          data.employment.length !== index + 1 && <hr />}
                      </>
                    ))}
                  </>
                )}
              </div>
            </div>
          )}
          {(data?.reference_contact1 || data?.reference_email1) &&
          <div className="row">
            <div className="col-3">Reference 1</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <>
                <div className="white">
                  <div>
                    <b>Name</b>
                    <p>{data?.reference_name1 ?? "Not Provided"}</p>
                  </div>
                  <div>
                    <b>Designation</b>
                    <p>{`${data?.reference_designation1  ?? "Not Provided"}`}</p>
                  </div>
                  <div>
                    <b>Address</b>
                    <p>{`${data?.reference_address1 ?? "Not Provided"}`}</p>
                  </div>
                  <div>
                    <b>Contact No</b>
                    <p>{data?.reference_contact1 ? `${data?.reference_contact1.split("-").length >1 ? "+"  :"" } ${data?.reference_contact1.split("-")[0]  ?? ""} ${data?.reference_contact1.split("-")[1] ?? ""}` : "Not Provided" }</p>
                  </div>
                  <div>
                    <b>Email Id</b>
                    <p>{data?.reference_email1 ?? "Not Provided"}</p>
                  </div>
                </div>
              </>
            </div>
          </div>}
          {(data?.reference_contact2 || data?.reference_email2) &&
          <div className="row">
            <div className="col-3">Reference 2</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <>
                <div className="white">
                  <div>
                    <b>Name</b>
                    <p>{data?.reference_name2 ?? "Not Provided"}</p>
                  </div>
                  <div>
                    <b>Designation</b>
                    <p>{`${data?.reference_designation2 ?? "Not Provided"}`}</p>
                  </div>
                  <div>
                    <b>Address</b>
                    <p>{`${data?.reference_address2 ?? "Not Provided"}`}</p>
                  </div>
                  <div>
                    <b>Contact No</b>
                    <p>{data?.reference_contact2 ? `${data?.reference_contact2.split("-").length >1 ? "+"  :"" } ${data?.reference_contact2.split("-")[0] ?? ""} ${data?.reference_contact2.split("-")[1] ?? ""}` : "Not Provided" }</p>
                  </div>
                  <div>
                    <b>Email Id</b>
                    <p>{data?.reference_email2 ?? "Not Provided"}</p>
                  </div>
                </div>
              </>
            </div>
          </div> }

          <div className="row">
            <div className="col-3">Area of Interest</div>
            <div className="col-1">:</div>
            <div className="col-7">{data?.interest_type ?? ""}</div>
          </div>
          <div className="row">
            <div className="col-3">Preferences</div>
            <div className="col-1">:</div>
            <div className="col-7">
              {data && data?.interest && data?.interest.length !== 0 && (
                <>
                  {JSON.parse(data?.interest)?.map((item, index) => (
                    <div>{item}</div>
                  ))}
                  {data?.any_other_domain && (
                    <div>{data?.any_other_domain}</div>
                  )}
                </>
              )}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-3"> CV File</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <button
                className="btn btn-approve btn-success"
                onClick={(e) => handleDownloadIdCert(data?.cv_upload)}
              >
                Download File
              </button>
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-3">
              Are you currently attached / committed to any Organization ? Give
              details
            </div>
            <div className="col-1">:</div>
            <div className="col-7">jjjjj</div>
          </div> */}
          {/* <div className="row">
            <div className="col-3"> Photo</div>
            <div className="col-1">:</div>
            <div className="col-7">
              <img
                className="res_photo"
                src={`${result}${data?.photo}`}
                alt=""
              />
            </div>
          </div> */}
          {/* {revData && revData?.length > 0 && (
            <div className="row">
              <div className="col-3">Reject Messages</div>
              <div className="col-1">:</div>
              <div className="col-7">
                {revData?.map((item, i) => (
                  <div key={i}>{item?.message}</div>
                ))}
              </div>
            </div>
          )} */}
          {data?.message && data?.message !== "" && (
            <div className="row">
              <div className="col-3">Admin Message</div>
              <div className="col-1">:</div>
              <div className="col-7">{data?.message}</div>
            </div>
          )}
        
          {data?.superadmin_message && data?.superadmin_message !== "" && (
            <div className="row">
              <div className="col-3">Super Admin Message</div>
              <div className="col-1">:</div>
              <div className="col-7">{data?.superadmin_message}</div>
            </div>
          )}

          {adminType === 1 && (
            <>
              {(data?.status === 0 || data?.status === 4) && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-approve btn-success mr-2"
                    onClick={(e) => saMessage(1, "admin")}
                  >
                    Submit for Acceptance
                  </button>
                  <button
                    type="button"
                    className="btn btn-approve btn-success mr-2"
                    onClick={(e) => saMessage(3, "admin")}
                  >
                    Revert
                  </button>

                  {/* <button type="button" className="btn btn-reject btn-danger">
                Reject
              </button> */}
                </div>
              )}
            </>
          )}
          {adminType === 2 && (
            <>
              {" "}
              {data?.status === 1 && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    onClick={(e) => saMessage(2, "sa")}
                    className="btn btn-approve btn-success mr-2"
                  >
                    Approve
                  </button>
                  {/* <button
                    type="button"
                    onClick={(e) => saMessage(3)}
                    className="btn btn-approve btn-warning mr-2"
                  >
                    Revert
                  </button> */}
                  <br />
                  <button
                    type="button"
                    onClick={(e) => saMessage(4, "sa")}
                    className="btn btn-reject  btn-danger "
                  >
                    Revert to Admin
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
